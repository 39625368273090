import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import {
  BlocklyJsOperator,
  initPropertyGetterBlock,
  initPropertySetterBlock,
  initPropertySetterByTimeBlock,
} from "common/blockly";
import {
  FONT_SIZE_MIN,
  FONT_SIZE_MAX,
  TEXTINPUT_MAX,
  SHADOW_SIZE_MIN,
  SHADOW_SIZE_MAX,
} from "common/components";

export const InitTextFontBlock = () => {
  Blockly.Blocks["text_font_family"] = {
    init: function () {
      this.appendDummyInput().appendField(
        new Blockly.FieldDropdown([
          ["M PLUS Rounded 1c", "MPLUSRounded1c"],
          ["Noto Sans JP", "NotoSansJP"],
          ["Noto Serif JP", "NotoSerifJP"],
        ]),
        "value"
      );
      this.setOutput(true, "String");
      this.setStyle("text_blocks");
    },
  };

  BlocklyJs["text_font_family"] = function (block: Blockly.BlockSvg) {
    const fontFamily = block.getField("value").getValue();
    return [`${fontFamily}`, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["text_font_weight"] = {
    init: function () {
      this.appendDummyInput().appendField(
        new Blockly.FieldDropdown([
          ["%{BKY_TEXT_FONT_WEIGHT_BOLD}", "Bold"],
          ["%{BKY_TEXT_FONT_WEIGHT_REGULAR}", "Regular"],
          ["%{BKY_TEXT_FONT_WEIGHT_LIGHT}", "Light"],
        ]),
        "value"
      );
      this.setOutput(true, "String");
      this.setStyle("text_blocks");
    },
  };

  BlocklyJs["text_font_weight"] = function (block: Blockly.BlockSvg) {
    const fontWeight = block.getField("value").getValue();
    return [`${fontWeight}`, BlocklyJsOperator.ORDER_NONE];
  };
};

export const InitActionTextPropertyBlock = (
  components: string[][],
  defaultComponent?: string | undefined
) => {
  function showEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      components.length === 0 ? [["パーツ", ""]] : components,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  Blockly.Blocks["component_property_getter_text_font_family"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_text_font_family",
          "%{BKY_GETTER_TEXT_FONT_FAMILY}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "text_blocks",
          "String"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };

  BlocklyJs["component_property_getter_text_font_family"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTextFontFamily("${component[0]}-text-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["component_property_setter_text_font_family"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_font_family",
          "%{BKY_SETTER_TEXT_FONT_FAMILY}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "text_blocks",
          "String"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };

  BlocklyJs["component_property_setter_text_font_family"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTextFontFamily("${component[0]}-text-${component[1]}","${value}");\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_text_font_weight"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_text_font_weight",
          "%{BKY_GETTER_TEXT_FONT_WEIGHT}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "text_blocks",
          "String"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };

  BlocklyJs["component_property_getter_text_font_weight"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTextFontWeight("${component[0]}-text-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["component_property_setter_text_font_weight"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_font_weight",
          "%{BKY_SETTER_TEXT_FONT_WEIGHT}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "text_blocks",
          "String"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };

  BlocklyJs["component_property_setter_text_font_weight"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTextFontWeight("${component[0]}-text-${component[1]}","${value}");\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_text_font_size"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_text_font_size",
          "%{BKY_GETTER_TEXT_FONT_SIZE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };

  BlocklyJs["component_property_getter_text_font_size"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTextFontSize("${component[0]}-text-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["component_property_setter_text_font_size"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_font_size",
          "%{BKY_SETTER_TEXT_FONT_SIZE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > FONT_SIZE_MAX) {
              return FONT_SIZE_MAX;
            } else if (value < FONT_SIZE_MIN) {
              return FONT_SIZE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };

  BlocklyJs["component_property_setter_text_font_size"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }TextFontSize("${component[1]}", "${value}");\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_text_font_size_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_font_size_by_step",
          "%{BKY_SETTER_TEXT_FONT_SIZE_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > FONT_SIZE_MAX) {
              return FONT_SIZE_MAX;
            } else if (value < -FONT_SIZE_MAX) {
              return -FONT_SIZE_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_text_font_size_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }TextFontSizeByStep("${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_text_font_size_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_text_font_size_by_time",
          "%{BKY_SETTER_TEXT_FONT_SIZE_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > FONT_SIZE_MAX) {
              return FONT_SIZE_MAX;
            } else if (value < FONT_SIZE_MIN) {
              return FONT_SIZE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_text_font_size_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }TextFontSizeByTime("${component[1]}", ${time}, ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_text"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_text",
          "%{BKY_GETTER_TEXT}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "text_blocks",
          "String"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_text"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.get${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Text("${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_MULTIPLICATION];
  };

  Blockly.Blocks["component_property_setter_text"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text",
          "%{BKY_SETTER_TEXT}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "text_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("TEXT")) {
          inputTarget.getField("TEXT").setValidator((value: string) => {
            if (value.length > TEXTINPUT_MAX) {
              return value.slice(0, TEXTINPUT_MAX);
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_text"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Text("${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_text_color"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_text_color",
          "%{BKY_GETTER_TEXT_COLOR}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "colour_blocks",
          "Colour"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_text_color"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTextColor("${component[0]}-text-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["component_property_setter_text_color"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_color",
          "%{BKY_SETTER_TEXT_COLOR}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "colour_blocks",
          "Colour"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_text_color"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTextColor("${component[0]}-text-${component[1]}",${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_text_shadow_color"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_text_shadow_color",
          "%{BKY_GETTER_TEXT_SHADOW_COLOR}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "colour_blocks",
          "Colour"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_text_shadow_color"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTextShadowColor("${component[0]}-text-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["component_property_setter_text_shadow_color"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_shadow_color",
          "%{BKY_SETTER_TEXT_SHADOW_COLOR}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "colour_blocks",
          "Colour"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_text_shadow_color"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTextShadowColor("${component[0]}-text-${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_text_shadow_radius"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_text_shadow_radius",
          "%{BKY_GETTER_TEXT_SHADOW_RADIUS}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_text_shadow_radius"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTextShadowRadius("${component[0]}-text-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };

  Blockly.Blocks["component_property_setter_text_shadow_radius"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_shadow_radius",
          "%{BKY_SETTER_TEXT_SHADOW_RADIUS}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > SHADOW_SIZE_MAX) {
              return SHADOW_SIZE_MAX;
            } else if (value < SHADOW_SIZE_MIN) {
              return SHADOW_SIZE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_text_shadow_radius"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTextShadowRadius("${component[0]}-text-${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_text_shadow_radius_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_text_shadow_radius_by_step",
          "%{BKY_SETTER_TEXT_SHADOW_RADIUS_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > SHADOW_SIZE_MAX) {
              return SHADOW_SIZE_MAX;
            } else if (value < -SHADOW_SIZE_MAX) {
              return -SHADOW_SIZE_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_text_shadow_radius_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTextShadowRadiusByStep("${component[0]}-text-${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_text_shadow_radius_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_text_shadow_radius_by_time",
          "%{BKY_SETTER_TEXT_SHADOW_RADIUS_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > SHADOW_SIZE_MAX) {
              return SHADOW_SIZE_MAX;
            } else if (value < SHADOW_SIZE_MIN) {
              return SHADOW_SIZE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_text_shadow_radius_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setTextShadowRadiusByTime("${component[0]}-text-${component[1]}", ${time}, ${value});\n`;
    return code;
  };
};
