export const BASE_WIDTH = 1280;
export const BASE_HEIGHT = 960;

const Constants = {
  major: process.env.REACT_APP_MAJOR_VERSION,
  minor: process.env.REACT_APP_MINOR_VERSION,
  buildNumber: process.env.REACT_APP_BUILD_NUMBER,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  apiHost: process.env.REACT_APP_API_HOST,
  apiAuthKey: process.env.REACT_APP_API_HEADER_AUTHORIZATION_KEY,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sentryEnv: process.env.REACT_APP_SENTRY_ENV,
  assetHost: process.env.REACT_APP_KIDS_ASSET_HOST,
  firebaseEmulatorsHost: process.env.REACT_APP_FIREBASE_EMULATORS_HOST,
  firebaseEmulatorsAuthPort: process.env.REACT_APP_FIREBASE_EMULATORS_AUTH_PORT,
  firebaseEmulatorsFirestorePort:
    process.env.REACT_APP_FIREBASE_EMULATORS_FIRESTORE_PORT,
};

export default Constants;
