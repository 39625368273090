import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;

  return (
    <RuntimeContainer {...props}>
      <polygon
        id={`shape-shape-${id}`}
        paintOrder="stroke"
        points={`${width / 2},${0} ${0},${height} ${width},${height}`}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={property.style.view.backgroundColor}
      />
    </RuntimeContainer>
  );
};

export default WrapComponent;
