import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getButtonShadowColor = (id: string) =>
  getShadowColor(`button-shape-container-${id}`);
export const setButtonShadowColor = (id: string, value: string) =>
  setShadowColor(`button-shape-container-${id}`, value);

export const getButtonShadowRadius = (id: string) =>
  getShadowRadius(`button-shape-container-${id}`);
export const setButtonShadowRadius = (id: string, value: number) =>
  setShadowRadius(`button-shape-container-${id}`, value);
export const setButtonShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`button-shape-container-${id}`, value);
export const setButtonShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`button-shape-container-${id}`, time, value);
