import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";

export const getStampWidth = (id: string) => $(`#stamp-${id}`).width();
export const getStampHeight = (id: string) => $(`#stamp-${id}`).height();

export const setStampSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#stamp-container-${id}`);
  const stamp = $(`#stamp-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth = stamp.width();
  const currentShapeHeight = stamp.height();

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const borderRadius = Number(stamp.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);
  stamp.css("border-radius", size * (borderRadius / 200));
  stamp.css("width", `${newShapeSize.x}`);
  stamp.css("height", `${newShapeSize.y}`);
};
export const setStampSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const stamp = $(`#stamp-${id}`);
  const currentShapeWidth = stamp.width();
  const currentShapeHeight = stamp.height();
  const newShapeWidth = widthStep === 0 ? 0 : currentShapeWidth + widthStep;
  const newShapeHeight = heightStep === 0 ? 0 : currentShapeHeight + heightStep;
  setStampSize(id, newShapeWidth, newShapeHeight);
};
export const setStampSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#stamp-container-${id}`);
  const stamp = $(`#stamp-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth = stamp.width();
  const currentShapeHeight = stamp.height();

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const borderRadius = Number(stamp.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);

  return Promise.all([
    anime({
      targets: `#stamp-container-${id}`,
      translateX: `-=${offset.x}`,
      translateY: `+=${offset.y}`,
      width: `+=${newContainerSize.x - currentContainerWidth}`,
      height: `+=${newContainerSize.y - currentContainerHeight}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#stamp-${id}`,
      width: `+=${newShapeSize.x - currentShapeWidth}`,
      height: `+=${newShapeSize.y - currentShapeHeight}`,
      borderRadius: size * (borderRadius / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
