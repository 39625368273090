import i18n from "i18n-js";
import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const borderWidth = property.style.view.borderWidth;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );
  return (
    <div
      className="flex-col-center"
      style={{
        width: width + borderWidth,
        height: height + borderWidth,
        backfaceVisibility: "hidden",
        WebkitBackfaceVisibility: "hidden",
        transform: `translateZ(0) scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <svg
        preserveAspectRatio="none"
        width={width + borderWidth}
        height={height + borderWidth}
        viewBox={`${-borderWidth / 2} ${-borderWidth / 2} ${
          width + borderWidth
        } ${height + borderWidth}`}
      >
        <rect
          x={0}
          y={0}
          rx={size * (property.style.view.borderRadius / 200)}
          width={width}
          height={height}
          paintOrder="stroke"
          stroke={property.style.view.borderColor}
          strokeWidth={borderWidth}
          fill={property.style.view.backgroundColor}
          opacity={property.style.view.opacity / 100}
        />
      </svg>
      <div className="flex-row-center w-full h-full !absolute top-0 left-0">
        <p
          className="select-none m-0 align-middle whitespace-pre-line"
          style={{
            fontFamily: property.style.text.fontFamily,
            letterSpacing: property.style.text.letterSpacing,
            fontSize: property.style.text.fontSize,
            color: property.style.text.color,
            textAlign: "center",
            opacity: property.style.text.opacity / 100,
            textShadow:
              property.style.text.textShadowRadius !== 0 &&
              `${property.style.text.textShadowOffset.width}px ${property.style.text.textShadowOffset.height}px ${property.style.text.textShadowRadius}px ${property.style.text.textShadowColor}`,
          }}
        >
          {i18n.t(property.text)}
        </p>
      </div>
    </div>
  );
};

export default WrapComponent;
