import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";

export const getButtonWidth = (id: string) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  return currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
};
export const getButtonHeight = (id: string) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerHeight = container.height();
  return currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;
};
export const setButtonSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const shape = $(`#button-shape-${id}`);
  const text = $(`#button-text-${id}`);

  const viewBox = svg.attr("viewBox").split(" ");

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newShapeSize.x / Number(container.data("defaultWidth"));
  const scaleY = newShapeSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;
  const borderRadius = Number(shape.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);

  svg.attr(
    "viewBox",
    `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`
  );

  shape.attr("rx", size * (borderRadius / 200));
  shape.attr("width", `${newShapeSize.x}`);
  shape.attr("height", `${newShapeSize.y}`);
  text.css(
    "font-size",
    `${newFontSize < defaultFontSize ? defaultFontSize : newFontSize}px`
  );
};
export const setButtonSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);

  const viewBox = svg.attr("viewBox").split(" ");

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const newShapeWidth =
    widthStep === undefined ? undefined : currentShapeWidth + widthStep;
  const newShapeHeight =
    heightStep === undefined ? undefined : currentShapeHeight + heightStep;

  setButtonSize(id, newShapeWidth, newShapeHeight);
};
export const setButtonSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#button-container-${id}`);
  const shape = $(`#button-shape-${id}`);
  const svg = $(`#button-svg-${id}`);

  const viewBox = svg.attr("viewBox").split(" ");

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newShapeSize.x / Number(container.data("defaultWidth"));
  const scaleY = newShapeSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;
  const borderRadius = Number(shape.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);

  return Promise.all([
    anime({
      targets: `#button-container-${id}`,
      translateX: `-=${offset.x}`,
      translateY: `+=${offset.y}`,
      width: `+=${newShapeSize.x - currentShapeWidth}`,
      height: `+=${newShapeSize.y - currentShapeHeight}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#button-svg-${id}`,
      viewBox: `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`,
      rx: size * (borderRadius / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#button-shape-${id}`,
      width: newShapeSize.x,
      height: newShapeSize.y,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#button-text-${id}`,
      fontSize: newFontSize < defaultFontSize ? defaultFontSize : newFontSize,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
