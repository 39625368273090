import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const {
    id,
    typeId,
    zIndex,
    property,
    pointerEvents,
    onClick,
    children,
  } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;

  return (
    <div
      id={`${typeId}-container-${id}`}
      className="absolute left-0 top-0 cursor-pointer will-change-transform"
      data-type-id={typeId}
      data-default-width={property.style.defaultLayout.width}
      data-default-height={property.style.defaultLayout.height}
      data-default-font-size={property.style.defaultText?.fontSize}
      style={{
        pointerEvents: pointerEvents ? pointerEvents : "auto",
        zIndex: zIndex,
        backfaceVisibility: "hidden",
        width: width + SizeOffset,
        height: height + SizeOffset,
        transform: `translateX(${property.style.transform.translateX}px) translateY(${property.style.transform.translateY}px) rotate(${property.style.transform.rotation}deg) scale(1)`,
        opacity: property.style.view.opacity / 100,
      }}
      onPointerDown={onClick}
    >
      {children}
    </div>
  );
};

export default WrapComponent;
