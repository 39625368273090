import chroma from "chroma-js";
import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const MARGIN = width < height ? width * 0.1 : height * 0.1;
  const THUMB_BORDER_WIDTH = property.style.view.borderWidth * 0.1;
  const THUMB_RADIUS = width < height ? width * 0.4 : height * 0.4;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;
  const thumbColorHsl = chroma(property.style.view.backgroundColor).hsl();
  const thumbColorRgba = chroma(property.style.view.backgroundColor).rgba();
  const trackColor = chroma
    .hsl(thumbColorHsl[0], thumbColorHsl[1], thumbColorHsl[2] + 0.1)
    .rgba();
  const size = Math.min(width, height);
  return (
    <svg
      id={id}
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${
        property.style.layout.width + SizeOffset
      } ${property.style.layout.height + SizeOffset}`}
    >
      <rect
        x={0}
        y={0}
        rx={size * (property.style.view.borderRadius / 200)}
        width={width}
        height={height}
        paintOrder="stroke"
        strokeWidth={property.style.view.borderWidth}
        stroke={property.style.view.borderColor}
        fill="white"
      />
      <rect
        x={MARGIN}
        y={MARGIN}
        rx={(size - MARGIN * 2) * (property.style.view.borderRadius / 200)}
        width={width - MARGIN * 2}
        height={height - MARGIN * 2}
        paintOrder="stroke"
        fill={`rgba(${trackColor[0]}, ${trackColor[1]}, ${trackColor[2]}, ${thumbColorRgba[3]})`}
      />
      <circle
        cx={THUMB_RADIUS}
        cy={THUMB_RADIUS + MARGIN}
        r={THUMB_RADIUS}
        paintOrder="stroke"
        fill={String(property.style.view.backgroundColor)}
        strokeWidth={THUMB_BORDER_WIDTH}
        stroke={property.style.view.borderColor}
        transform={`translate(${
          property.value
            ? width - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
            : MARGIN + THUMB_BORDER_WIDTH / 2
        } 0)`}
      />
    </svg>
  );
};

export default WrapComponent;
