import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;

  return (
    <svg
      id={id}
      overflow="visible"
      preserveAspectRatio="none"
      viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${width + SizeOffset} ${
        height + SizeOffset
      }`}
    >
      <polygon
        paintOrder="stroke"
        points={`${width / 2},${0} ${0},${height} ${width},${height}`}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        strokeLinejoin="miter"
        strokeMiterlimit={100}
        fill={property.style.view.backgroundColor}
      />
    </svg>
  );
};

export default WrapComponent;
