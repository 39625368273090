import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";

export const getCheckboxWidth = (id: string) =>
  $(`#checkbox-container-${id}`).width();

export const getCheckboxHeight = (id: string) =>
  $(`#checkbox-container-${id}`).height();

export const setCheckboxSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#checkbox-container-${id}`);
  const checkbox = $(`#checkbox-${id}`);
  const text = $(`#checkbox-text-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const [newContainerSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth,
    currentContainerHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newContainerSize.x / Number(container.data("defaultWidth"));
  const scaleY = newContainerSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;

  checkbox.css(
    "width",
    `${newFontSize < defaultFontSize ? defaultFontSize : newFontSize}`
  );
  checkbox.css(
    "height",
    `${newFontSize < defaultFontSize ? defaultFontSize : newFontSize}`
  );
  text.css(
    "font-size",
    `${newFontSize < defaultFontSize ? defaultFontSize : newFontSize}px`
  );
  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);
};

export const setCheckboxSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const container = $(`#checkbox-container-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const newShapeWidth =
    widthStep === undefined ? undefined : currentContainerWidth + widthStep;
  const newShapeHeight =
    heightStep === undefined ? undefined : currentContainerHeight + heightStep;

  setCheckboxSize(id, newShapeWidth, newShapeHeight);
};

export const setCheckboxSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#checkbox-container-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const [newContainerSize, , offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth,
    currentContainerHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newContainerSize.x / Number(container.data("defaultWidth"));
  const scaleY = newContainerSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;

  return Promise.all([
    anime({
      targets: `#checkbox-container-${id}`,
      translateX: `-=${offset.x}`,
      translateY: `+=${offset.y}`,
      width: newContainerSize.x,
      height: newContainerSize.y,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#checkbox-${id}`,
      width: newFontSize < defaultFontSize ? defaultFontSize : newFontSize,
      height: newFontSize < defaultFontSize ? defaultFontSize : newFontSize,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#checkbox-text-${id}`,
      fontSize: newFontSize < defaultFontSize ? defaultFontSize : newFontSize,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
