import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );
  return (
    <div className="flex-col-center w-full h-full">
      <img
        alt=""
        id={id}
        data-type="image"
        src={property.style.image.uri}
        style={{
          width: property.style.layout.width,
          height: property.style.layout.height,
          borderStyle: property.style.view.borderStyle,
          borderColor: property.style.view.borderColor,
          borderWidth: property.style.view.borderWidth / 2,
          borderRadius: size * (property.style.view.borderRadius / 200),
        }}
      />
    </div>
  );
};

export default WrapComponent;
