import $ from "jquery";
import anime from "animejs";
import {
  X_MIN,
  X_MAX,
  Y_MIN,
  Y_MAX,
  SCALE_MIN,
  SCALE_MAX,
  RENDER_ORDER,
} from "common/components";
import { Vector2D } from "common/types";
import { calcVector } from "common/utils";

export const getTransformPropertyValue = (obj: JQuery<HTMLElement>) => {
  const matrix =
    obj.css("-webkit-transform") ||
    obj.css("-moz-transform") ||
    obj.css("-ms-transform") ||
    obj.css("-o-transform") ||
    obj.css("transform");
  if (matrix !== "none" && matrix !== undefined) {
    const values = matrix.split("(")[1].split(")")[0].split(",");
    const a = Number(values[0]);
    const b = Number(values[1]);
    const c = Number(values[2]);
    const translateX = Number(values[4].trim());
    const translateY = Number(values[5].trim());
    const radius = Math.atan2(b, a);
    const scaleX = Math.cos(radius) * a - Math.sin(radius) * c; // https://stackoverflow.com/questions/64168159/transformation-matrix-rotation-not-preserving-local-axis-scaling
    return [translateX, translateY, radius * (180 / Math.PI), scaleX];
  } else {
    return [0, 0, 0, 0];
  }
};

export const calcOffset = (
  preWidth: number,
  preHeight: number,
  radius: number,
  delta: Vector2D
): Vector2D => {
  const dragPointUnitVector = {
    x: delta.x === 0 ? 0 : 1,
    y: delta.y === 0 ? 0 : 1,
  };
  const preRefPoint = calcVector(
    (-preWidth * dragPointUnitVector.x) / 2,
    (preHeight * dragPointUnitVector.y) / 2,
    -radius
  );

  const originOffset = calcVector(delta.x / 2, delta.y / 2, 0);
  const newWidth = preWidth + delta.x;
  const newHeight = preHeight + delta.y;
  const newRefPoint = calcVector(
    (-newWidth * dragPointUnitVector.x) / 2,
    (newHeight * dragPointUnitVector.y) / 2,
    -radius
  );

  const offsetX = newRefPoint.x - (preRefPoint.x - originOffset.x);
  const offsetY = newRefPoint.y - (preRefPoint.y + originOffset.y);

  return { x: offsetX, y: offsetY };
};

export const getTranslateX = (id: string) => {
  const obj = $(`#${id}`);
  const width = obj.width();
  const [translateX, ,] = getTransformPropertyValue(obj);
  return translateX + width / 2;
};
export const getTranslateY = (id: string) => {
  const obj = $(`#${id}`);
  const height = obj.height();
  const [, translateY] = getTransformPropertyValue(obj);
  return translateY + height / 2;
};
export const setTranslateXY = (
  id: string,
  x?: number | undefined,
  y?: number | undefined
) => {
  const obj = $(`#${id}`);
  const width = obj.width();
  const height = obj.height();
  const [translateX, translateY, rotate, scale] =
    getTransformPropertyValue(obj);
  let targetX: number;
  if (x > X_MAX) {
    targetX = X_MAX;
  } else if (x < X_MIN) {
    targetX = X_MIN;
  } else {
    targetX = x;
  }

  let targetY: number;
  if (y > Y_MAX) {
    targetY = Y_MAX;
  } else if (y < Y_MIN) {
    targetY = Y_MIN;
  } else {
    targetY = y;
  }

  obj.css(
    "transform",
    `translateX(${
      x === undefined ? translateX : targetX - width / 2
    }px) translateY(${
      y === undefined ? translateY : targetY - height / 2
    }px) rotate(${rotate}deg) scale(${scale})`
  );
};
export const setTranslateXYByStep = (
  id: string,
  x?: number | undefined,
  y?: number | undefined
) => {
  const obj = $(`#${id}`);
  const width = obj.width();
  const height = obj.height();
  const [translateX, translateY, rotate, scale] =
    getTransformPropertyValue(obj);
  const newTranslateX = translateX + (x === undefined ? 0 : x);
  const newTranslateY = translateY + (y === undefined ? 0 : y);

  let targetX: number;
  if (newTranslateX > X_MAX - width / 2) {
    targetX = X_MAX - width / 2;
  } else if (newTranslateX < X_MIN - width / 2) {
    targetX = X_MIN - width / 2;
  } else {
    targetX = newTranslateX;
  }

  let targetY: number;
  if (newTranslateY > Y_MAX - height / 2) {
    targetY = Y_MAX - height / 2;
  } else if (newTranslateY < Y_MIN - height / 2) {
    targetY = Y_MIN - height / 2;
  } else {
    targetY = newTranslateY;
  }

  obj.css(
    "transform",
    `translateX(${targetX}px) translateY(${targetY}px) rotate(${rotate}deg) scale(${scale})`
  );
};
export const setTranslateXYByTime = (
  id: string,
  time: number,
  x?: number | undefined,
  y?: number | undefined
) => {
  const obj = $(`#${id}`);
  const width = obj.width();
  const height = obj.height();
  const [translateX, translateY] = getTransformPropertyValue(obj);

  let targetX: number;
  if (x > X_MAX) {
    targetX = X_MAX;
  } else if (x < X_MIN) {
    targetX = X_MIN;
  } else {
    targetX = x;
  }

  let targetY: number;
  if (y > Y_MAX) {
    targetY = Y_MAX;
  } else if (y < Y_MIN) {
    targetY = Y_MIN;
  } else {
    targetY = y;
  }

  return anime({
    targets: `#${id}`,
    translateX: x === undefined ? translateX : targetX - width / 2,
    translateY: y === undefined ? translateY : targetY - height / 2,
    duration: time * 1000,
    easing: "easeInOutQuad",
  }).finished;
};
export const setTranslateXYByStepAndTime = (
  id: string,
  time: number,
  x?: number | undefined,
  y?: number | undefined
) => {
  const obj = $(`#${id}`);
  const width = obj.width();
  const height = obj.height();
  const [translateX, translateY] = getTransformPropertyValue(obj);

  const newTranslateX = translateX + (x === undefined ? 0 : x);
  const newTranslateY = translateY + (y === undefined ? 0 : y);

  let targetX: number;
  if (newTranslateX > X_MAX - width / 2) {
    targetX = X_MAX - width / 2;
  } else if (newTranslateX < X_MIN - width / 2) {
    targetX = X_MIN - width / 2;
  } else {
    targetX = newTranslateX;
  }

  let targetY: number;
  if (newTranslateY > Y_MAX - height / 2) {
    targetY = Y_MAX - height / 2;
  } else if (newTranslateY < Y_MIN - height / 2) {
    targetY = Y_MIN - height / 2;
  } else {
    targetY = newTranslateY;
  }

  return anime({
    targets: `#${id}`,
    translateX: x === undefined ? translateX : targetX,
    translateY: y === undefined ? translateY : targetY,
    duration: time * 1000,
    easing: "easeInOutQuad",
  }).finished;
};

export const setOrder = (id: string, order: RENDER_ORDER) => {
  const obj = $(`#${id}`);
  const zIndex = Number(obj.css("z-index"));

  if (order === RENDER_ORDER.BACK) {
    obj
      .parent()
      .children()
      .map((index, dom) => {
        const domIndex = Number(dom.style.zIndex);
        if (domIndex < zIndex) {
          dom.style.zIndex = String(domIndex + 1);
        }
        return dom;
      });
    obj.css("z-index", "0");
  } else {
    const doms = obj
      .parent()
      .children()
      .map((index, dom) => {
        const domIndex = Number(dom.style.zIndex);
        if (domIndex > zIndex) {
          dom.style.zIndex = String(domIndex - 1);
        }
        return dom;
      });
    obj.css("z-index", `${doms.length - 1}`);
  }
};

export const getRotate = (id: string) => {
  const obj = $(`#${id}`);
  const [, , rotate] = getTransformPropertyValue(obj);
  return rotate;
};

export const setRotate = (id: string, value: number) => {
  const obj = $(`#${id}`);
  const [translateX, translateY, , scale] = getTransformPropertyValue(obj);
  obj.css(
    "transform",
    `translateX(${translateX}px) translateY(${translateY}px) rotate(${
      value < 0 ? 360 + value : value
    }deg) scale(${scale})`
  );
};

export const setRotateByStep = (id: string, value: number) => {
  const obj = $(`#${id}`);
  const [translateX, translateY, rotate, scale] =
    getTransformPropertyValue(obj);
  const newRotate = rotate + value;
  obj.css(
    "transform",
    `translateX(${translateX}px) translateY(${translateY}px) rotate(${
      newRotate < 0 ? 360 + newRotate : newRotate
    }deg) scale(${scale})`
  );
};

export const setRotateByTime = (id: string, time: number, value: number) => {
  return anime({
    targets: `#${id}`,
    rotate: value,
    duration: time * 1000,
    easing: "easeInOutQuad",
  }).finished;
};

export const getScale = (id: string) => {
  const obj = $(`#${id}`);
  const [, , , scale] = getTransformPropertyValue(obj);
  return scale;
};
export const setScale = (id: string, value: number) => {
  const obj = $(`#${id}`);
  const [translateX, translateY, rotate] = getTransformPropertyValue(obj);
  let target: number;
  if (value > SCALE_MAX) {
    target = SCALE_MAX;
  } else if (value < SCALE_MIN) {
    target = SCALE_MIN;
  } else {
    target = value;
  }
  obj.css(
    "transform",
    `translateX(${translateX}px) translateY(${translateY}px) rotate(${rotate}deg) scale(${target})`
  );
};

export const setScaleByStep = (id: string, value: number) => {
  const obj = $(`#${id}`);
  const [translateX, translateY, rotate, scale] =
    getTransformPropertyValue(obj);
  let target: number;
  if (value + scale > SCALE_MAX) {
    target = SCALE_MAX;
  } else if (value + scale < SCALE_MIN) {
    target = SCALE_MIN;
  } else {
    target = value + scale;
  }
  obj.css(
    "transform",
    `translateX(${translateX}px) translateY(${translateY}px) rotate(${rotate}deg) scale(${target})`
  );
};

export const setScaleByTime = (id: string, time: number, value: number) => {
  let target: number;
  if (value > SCALE_MAX) {
    target = SCALE_MAX;
  } else if (value < SCALE_MIN) {
    target = SCALE_MIN;
  } else {
    target = value;
  }
  return anime({
    targets: `#${id}`,
    scale: target,
    duration: time * 1000,
    easing: "easeInOutQuad",
  }).finished;
};
