import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { BlocklyJsOperator } from "common/blockly";

export const InitSoundBlock = () => {
  Blockly.Blocks["play_sound"] = {
    init: function () {
      this.appendDummyInput()
        .appendField(
          new Blockly.FieldDropdown([
            ["pop", "pop.mp3"],
            ["pop-on", "pop-on.mp3"],
            ["pop-up-on", "pop-up-on.mp3"],
            ["pop-down", "pop-down.mp3"],
            ["pop-off", "pop-off.mp3"],
          ]),
          "value"
        )
        .appendField("のおとをならす");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setStyle("colour_blocks");
    },
  };
  BlocklyJs["play_sound"] = function (block: Blockly.BlockSvg) {
    const sound = block.getField("value").getValue();
    const code = `codeGen.playSound("${sound}");`;
    return code;
  };

  Blockly.Blocks["play_sound_loop"] = {
    init: function () {
      this.appendDummyInput()
        .appendField(
          new Blockly.FieldDropdown([
            ["pop", "pop.mp3"],
            ["pop-on", "pop-on.mp3"],
            ["pop-up-on", "pop-up-on.mp3"],
            ["pop-down", "pop-down.mp3"],
            ["pop-off", "pop-off.mp3"],
          ]),
          "value"
        )
        .appendField("のおとをならす");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setStyle("colour_blocks");
    },
  };
  BlocklyJs["play_sound_loop"] = function (block: Blockly.BlockSvg) {
    const sound = block.getField("value").getValue();
    const code = `codeGen.playSound("${sound}");`;
    return code;
  };

  Blockly.Blocks["stop_sound"] = {
    init: function () {},
  };
  BlocklyJs["stop_sound"] = function (block: Blockly.BlockSvg) {
    const code = "";
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["stop_all_sound"] = {
    init: function () {},
  };
  BlocklyJs["stop_all_sound"] = function (block: Blockly.BlockSvg) {
    const code = "";
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["change_sound_volume"] = {
    init: function () {},
  };
  BlocklyJs["change_sound_volume"] = function (block: Blockly.BlockSvg) {
    const code = "";
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["change_sound_volume_by_step"] = {
    init: function () {},
  };
  BlocklyJs["change_sound_volume_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const code = "";
    return [code, BlocklyJsOperator.ORDER_NONE];
  };
};
