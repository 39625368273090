import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;
  const width = property.style.layout.width * scale;
  const height = property.style.layout.height * scale;
  const borderWidth = property.style.view.borderWidth * scale;

  return (
    <svg
      display="block"
      stroke={property.style.view.borderColor}
      fill={property.style.view.backgroundColor}
      width={width}
      height={height}
      viewBox={`${-borderWidth / 2} ${-borderWidth / 2} ${
        width + borderWidth
      } ${height + borderWidth}`}
    >
      <rect
        x={0}
        y={0}
        rx={height / 2}
        ry={height / 2}
        paintOrder="stroke"
        width={property.style.layout.width}
        height={property.style.layout.height}
        stroke={property.style.view.borderColor}
        strokeWidth={borderWidth}
        fill={property.style.view.backgroundColor}
      />
    </svg>
  );
};

export default WrapComponent;
