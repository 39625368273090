import produce from "immer";
import DragLayerComponent from "./drag";
import DesignComponent from "./design";
import RuntimeComponent from "./runtime";
import {
  ComponentType,
  StarStyleProps,
  BorderStypeType,
  DefaultComponentProperty,
} from "common/components/types";

const Star: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  RuntimeComponent: RuntimeComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_STAR";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_STAR";

    const initalWidth = 100;
    const initalHeight = 100;

    draft.style.defaultLayout.width = initalWidth;
    draft.style.defaultLayout.height = initalHeight;
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultStar = StarStyleProps;
    draft.style.defaultView.borderWidth = 4;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderStyle = BorderStypeType.SOLID;

    draft.style.layout.width = initalWidth;
    draft.style.layout.height = initalHeight;
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.star = StarStyleProps;
    draft.style.view.borderWidth = 4;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderStyle = BorderStypeType.SOLID;
  }),
};

export default Star;
