import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );
  return (
    <>
      <svg
        id={id}
        overflow="visible"
        preserveAspectRatio="none"
        viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${
          property.style.layout.width + SizeOffset
        } ${property.style.layout.height + SizeOffset}`}
      >
        <rect
          x={0}
          y={0}
          rx={size * (property.style.view.borderRadius / 200)}
          paintOrder="stroke"
          width={property.style.layout.width}
          height={property.style.layout.height}
          stroke={property.style.view.borderColor}
          strokeWidth={property.style.view.borderWidth}
          fill={property.style.view.backgroundColor}
        />
      </svg>
      <div
        id={id}
        className="flex-row-center w-full h-full !absolute top-0 left-0"
      >
        <p
          className="select-none m-0 align-middle whitespace-pre-line"
          style={{
            fontFamily: property.style.text.fontFamily,
            letterSpacing: property.style.text.letterSpacing,
            fontSize: property.style.text.fontSize,
            color: property.style.text.color,
            textAlign: "center",
            opacity: property.style.text.opacity / 100,
            textShadow:
              property.style.text.textShadowRadius !== 0 &&
              `${property.style.text.textShadowOffset.width}px ${property.style.text.textShadowOffset.height}px ${property.style.text.textShadowRadius}px ${property.style.text.textShadowColor}`,
          }}
        >
          {property.text}
        </p>
      </div>
    </>
  );
};

export default WrapComponent;
