import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";

export const getImageWidth = (id: string) => $(`#image-${id}`).width();
export const getImageHeight = (id: string) => $(`#image-${id}`).height();

export const setImageSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#image-container-${id}`);
  const image = $(`#image-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth = image.width();
  const currentShapeHeight = image.height();

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const borderRadius = Number(image.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);
  image.css("border-radius", size * (borderRadius / 200));
  image.css("width", `${newShapeSize.x}`);
  image.css("height", `${newShapeSize.y}`);
};
export const setImageSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const image = $(`#image-${id}`);
  const currentShapeWidth = image.width();
  const currentShapeHeight = image.height();
  const newShapeWidth = widthStep === 0 ? 0 : currentShapeWidth + widthStep;
  const newShapeHeight = heightStep === 0 ? 0 : currentShapeHeight + heightStep;
  setImageSize(id, newShapeWidth, newShapeHeight);
};
export const setImageSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#image-container-${id}`);
  const image = $(`#image-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth = image.width();
  const currentShapeHeight = image.height();

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const borderRadius = Number(image.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);

  return Promise.all([
    anime({
      targets: `#image-container-${id}`,
      translateX: `-=${offset.x}`,
      translateY: `+=${offset.y}`,
      width: `+=${newContainerSize.x - currentContainerWidth}`,
      height: `+=${newContainerSize.y - currentContainerHeight}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#image-${id}`,
      width: `+=${newShapeSize.x - currentShapeWidth}`,
      height: `+=${newShapeSize.y - currentShapeHeight}`,
      borderRadius: size * (borderRadius / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
