export const initPropertyGetterBlock = (
  type: string,
  message: string,
  componentsField: string[][],
  style: string,
  output?: string
) => {
  return {
    type: type,
    message0: message,
    args0: [
      {
        type: "field_dropdown",
        name: "component_id",
        options: componentsField,
      },
    ],
    output: output,
    style: style,
  };
};

export const initPropertySetterBlock = (
  type: string,
  message: string,
  componentsField: string[][],
  style: string,
  check?: string
) => {
  return {
    type: type,
    message0: message,
    args0: [
      {
        type: "field_dropdown",
        name: "component_id",
        options: componentsField,
      },
      {
        type: "input_value",
        name: "value",
        check: check,
      },
    ],
    previousStatement: null,
    nextStatement: null,
    style: style,
  };
};

export const initPropertySetterByTimeBlock = (
  type: string,
  message: string,
  componentsField: string[][],
  style: string,
  check?: string
) => {
  return {
    type: type,
    message0: message,
    args0: [
      {
        type: "input_value",
        name: "time",
        check: "Number",
      },
      {
        type: "field_dropdown",
        name: "component_id",
        options: componentsField,
      },
      {
        type: "input_value",
        name: "value",
        check: check,
      },
    ],
    previousStatement: null,
    nextStatement: null,
    style: style,
  };
};

export const sizeTimeBlockGen = (
  type: string,
  componentsField: string[][]
) => ({
  type: type,
  message0: "%{BKY_SETTER_WIDTH_HEIGHT_BY_TIME}",
  args0: [
    {
      type: "input_value",
      name: "time",
      check: "Number",
    },
    {
      type: "field_dropdown",
      name: "component_id",
      options: componentsField,
    },
    {
      type: "input_value",
      name: "width",
      check: "Number",
    },
    {
      type: "input_value",
      name: "height",
      check: "Number",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  style: "math_blocks",
});
