import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getImageShadowColor = (id: string) =>
  getShadowColor(`image-shape-container-${id}`);
export const setImageShadowColor = (id: string, value: string) =>
  setShadowColor(`image-shape-container-${id}`, value);

export const getImageShadowRadius = (id: string) =>
  getShadowRadius(`image-shape-container-${id}`);
export const setImageShadowRadius = (id: string, value: number) =>
  setShadowRadius(`image-shape-container-${id}`, value);
export const setImageShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`image-shape-container-${id}`, value);
export const setImageShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`image-shape-container-${id}`, time, value);
