import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { client } from "common/apiClient";

export const getUserProjectContent = createAsyncThunk(
  "creator/getUserProjectContent",
  async ({
    authUser,
    source,
    uid,
    project_id,
  }: {
    authUser: User;
    source: string;
    uid: string;
    project_id: string;
  }) => {
    const token = await authUser.getIdToken(false);
    const response = await client({
      token: token,
    }).get(
      `/v1/projects/user/runtime/content/${project_id}?source=${source}&uid=${uid}`
    );
    return { ...response.data.data };
  }
);
