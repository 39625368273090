import { Vector2D } from "common/types";

export const creatStarPoint = (
  width: number,
  height: number,
  numPoints: number,
  innerRadius: number,
  outerRadius: number
) => {
  const points = [];
  const center: Vector2D = { x: width / 2, y: height / 2 + height * 0.05 };
  const radius: Vector2D = { x: width / 2, y: height / 2 };
  const angle = Math.PI / numPoints; // 2PI / numPoints / 2

  for (let i = 0; i < numPoints * 2; i++) {
    const ratio = i & 1 ? innerRadius / outerRadius : 1;
    points.push({
      x: center.x + radius.x * ratio * Math.sin(i * angle),
      y: center.y - radius.y * ratio * Math.cos(i * angle),
    });
  }

  var pointsStr = "";
  points.forEach((p) => {
    pointsStr = pointsStr.concat(` ${p.x},${p.y}`);
  });

  return pointsStr;
};
