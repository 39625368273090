import { ScreenOrientation } from "features/preview/types";

export const SCREEN_SIZE = {
  // visible area based on 1280x960 size
  [ScreenOrientation.LANDSCAPE]: {
    WIDTH: 1024,
    HEIGHT: 768,
  },
  [ScreenOrientation.PORTRAIT]: {
    WIDTH: 1024,
    HEIGHT: 768,
  },
};
export const DEFAULT_SCREEN_ORIENTATION = ScreenOrientation.LANDSCAPE; // orientation: "portrait"(縦) | "landscape"(横)
