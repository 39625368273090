import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitTextInputBlock = (
  components: string[][],
  defaultComponent?: string | undefined
) => {
  function showEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      components.length === 0 ? [["パーツ", ""]] : components,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  Blockly.Blocks["textinput_value_change_event"] = {
    init: function () {
      const componentsField = new Blockly.FieldDropdown(
        components.length === 0 ? [["パーツ", ""]] : components
      );
      componentsField.showEditor = showEditor;
      if (defaultComponent) {
        componentsField.setValue(defaultComponent);
      }
      this.appendDummyInput()
        .appendField(componentsField, "component_id")
        .appendField("%{BKY_TEXTINPUT_EVENT_VALUE_CHANGE}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["textinput_value_change_event"] = function (
    block: Blockly.BlockSvg
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const code = `${logic}\n`;
    return code;
  };
};
