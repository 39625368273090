import { Howl } from "howler";
import Constants from "common/constant";

export const playSound = (sound: string) => {
  new Howl({
    mute: false,
    volume: 0.5,
    autoplay: true,
    loop: false,
    preload: true,
    src: [`${Constants.assetHost}/assets/sounds/${sound}`],
  });
};
