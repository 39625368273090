import * as Blockly from "blockly";
import Jp from "blockly/msg/ja";

export const InitMsg = (locale: string) => {
  if (locale === "ja") {
    Blockly.setLocale(Jp);
    Blockly.Msg["DUPLICATE_BLOCK"] = "コピー";
    Blockly.Msg["ADD_COMMENT"] = "コメントをついか";
    Blockly.Msg["CHANGE_VALUE_TITLE"] = "あたいをかえる：";
    Blockly.Msg["COLLAPSE_BLOCK"] = "ブロックをおりたたむ";
    Blockly.Msg["ENABLE_BLOCK"] = "ブロックをゆうこうにする";
    Blockly.Msg["DISABLE_BLOCK"] = "ブロックをむこうにする";
    Blockly.Msg["DELETE_BLOCK"] = "ブロックをさくじょする";
    Blockly.Msg["DELETE_X_BLOCKS"] = "ブロックをさくじょする";
    Blockly.Msg["EXTERNAL_INPUTS"] = "がいぶにゅうりょく";

    Blockly.Msg["CONTROLS_REPEAT_TITLE"] = "%1 かいくりかえす";
    Blockly.Msg["CONTROLS_REPEAT_INPUT_DO"] = "じっこう";
    Blockly.Msg["CONTROLS_WHILEUNTIL_OPERATOR_UNTIL"] =
      "くりかえす：おわるじょうけん";
    Blockly.Msg["CONTROLS_WHILEUNTIL_OPERATOR_WHILE"] =
      "くりかえす：つづけるじょうけん";
    Blockly.Msg["CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK"] =
      "ループからぬけだす";
    Blockly.Msg["CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE"] =
      "ループのつぎのくりかえすしょりをぞっこうします";

    Blockly.Msg["CONTROLS_IF_MSG_THEN"] = "じっこう";

    Blockly.Msg["MATH_ROUND_OPERATOR_ROUND"] = "ししゃごにゅう";
    Blockly.Msg["MATH_ROUND_OPERATOR_ROUNDDOWN"] = "きりすて";
    Blockly.Msg["MATH_ROUND_OPERATOR_ROUNDUP"] = "きりあげ";
    Blockly.Msg["MATH_ROUND_TOOLTIP"] = "すうちをきりあげるかきりすてる";
    Blockly.Msg["MATH_MODULO_TITLE"] = "%1÷%2のあまり";
    Blockly.Msg["MATH_MODULO_TOOLTIP"] =
      "2つのすうちのわりさんのあまりをかえす。";
    Blockly.Msg["MATH_CONSTRAIN_TITLE"] =
      "%1 を %2 いじょう %3 いかのはんいにせいげん";
    Blockly.Msg["MATH_CONSTRAIN_TOOLTIP"] =
      "していしたじょうげんとかげんのあいだにあたいをせいげんする（じょうげんとかげんのあたいをふくむ）。";
    Blockly.Msg["MATH_RANDOM_FLOAT_TITLE_RANDOM"] = "1みまんのせいのらんすう";
    Blockly.Msg["MATH_RANDOM_INT_TITLE"] = "%1から%2までのランダムなせいすう";

    Blockly.Msg["COLOUR_RANDOM_TITLE"] = "ランダムないろ";
    Blockly.Msg["COLOUR_RANDOM_TOOLTIP"] = "ランダムにいろをえらぶ。";
    Blockly.Msg["COLOUR_RGB_BLUE"] = "あお";
    Blockly.Msg["COLOUR_RGB_GREEN"] = "みどり";
    Blockly.Msg["COLOUR_RGB_RED"] = "あか";
    Blockly.Msg["COLOUR_RGB_TITLE"] = "いろ:";
    Blockly.Msg["COLOUR_BLEND_COLOUR1"] = "いろ 1";
    Blockly.Msg["COLOUR_BLEND_COLOUR2"] = "いろ 2";
    Blockly.Msg["COLOUR_BLEND_RATIO"] = "ひりつ";
    Blockly.Msg["COLOUR_BLEND_TITLE"] = "ブレンド";

    Blockly.Msg["NEW_VARIABLE"] = "へんすうをつくる";
    Blockly.Msg["NEW_VARIABLE_TITLE"] = "あたらしいへんすうのなまえ:";
    Blockly.Msg["RENAME_VARIABLE"] = "へんすうのなまえをかえる…";
    Blockly.Msg["RENAME_VARIABLE_TITLE"] = `"%1"のなまえをかえる：`;
    Blockly.Msg["DELETE_VARIABLE"] = "へんすう '%1' をさくじょ";

    Blockly.Msg["PROCEDURES_DEFNORETURN_TITLE"] = "かんすう";
    Blockly.Msg["PROCEDURES_DEFNORETURN_PROCEDURE"] = "なにかする";
    Blockly.Msg["PROCEDURES_MUTATORARG_TITLE"] = "にゅうりょくめい:";
    Blockly.Msg["PROCEDURES_MUTATORARG_TOOLTIP"] =
      "かんすうへのにゅうりょくのついか。";
    Blockly.Msg["PROCEDURES_MUTATORCONTAINER_TITLE"] = "にゅうりょく";
    Blockly.Msg["PROCEDURES_DEFNORETURN_TOOLTIP"] =
      "しゅつりょくなしのかんすうをさくせいします。";

    Blockly.Msg["PROCEDURES_DEFRETURN_TITLE"] = "かんすう";
    Blockly.Msg["PROCEDURES_DEFRETURN_PROCEDURE"] = "なにかする";
    Blockly.Msg["PROCEDURES_DEFRETURN_RETURN"] = "かえす";
    Blockly.Msg["PROCEDURES_DEFRETURN_TOOLTIP"] =
      "ひとつのしゅつりょくをもつかんすうをさくせいします。";

    Blockly.Msg["DEFAULT_COMPONENT"] = "パーツ";
    Blockly.Msg["EVENT_CLICK"] = "がクリックされたとき";

    Blockly.Msg["SCREEN_EVENT_LOADING"] = "がひょうじされたとき";
    Blockly.Msg["SCREEN_EVENT_NAVIGATE"] = "へいどう";
    Blockly.Msg["SCREEN_GETTER_BACKGROUND_COLOR"] = "のはいけいのいろ";
    Blockly.Msg["SCREEN_SETTER_BACKGROUND_COLOR"] = "のはいけいのいろを";

    Blockly.Msg["TOGGLE_GETTER_VALUE"] = "%1のあたい";
    Blockly.Msg["TOGGLE_SETTER_VALUE"] = "%1のあたいを%2にする";

    Blockly.Msg["CHECKBOX_EVENT_CHECK"] = "がチェックされたとき";
    Blockly.Msg["SWITCH_EVENT_VALUE_CHANGE"] = "のがかわったとき";

    Blockly.Msg["TEXTINPUT_EVENT_VALUE_CHANGE"] = "がにゅうりょくされたとき";
    Blockly.Msg["TEXTINPUT_GETTER_VALUE"] = "%1がにゅうりょくされたあたい";
    Blockly.Msg["TEXTINPUT_SETTER_VALUE"] = "%1のあたいを%2にする";

    Blockly.Msg["IMAGE_SETTER_SRC"] = "%1のがぞうを%2にする";

    Blockly.Msg["DISPLAY_ORDER"] = "のひょうじを";
    Blockly.Msg["DISPLAY_ORDER_TOP"] = "いちばんうえ";
    Blockly.Msg["DISPLAY_ORDER_BOTTOM"] = "いちばんした";

    Blockly.Msg["GETTER_OPACITY"] = "%1のとうめいど";
    Blockly.Msg["SETTER_OPACITY"] = "%1のとうめいどを%2にする";
    Blockly.Msg["SETTER_OPACITY_BY_STEP"] = "%1とうめいどを%2ずつかえる";
    Blockly.Msg["SETTER_OPACITY_BY_TIME"] =
      "%1びょうで%2のとうめいどを%3にする";

    Blockly.Msg["GETTER_BACKGROUND_COLOR"] = "%1のはいけいのいろ";
    Blockly.Msg["SETTER_BACKGROUND_COLOR"] = "%1のはいけいのいろを%2にする";

    Blockly.Msg["GETTER_BORDER_COLOR"] = "%1のわくのいろ";
    Blockly.Msg["SETTER_BORDER_COLOR"] = "%1のわくのいろを%2にする";
    Blockly.Msg["GETTER_BORDER_WIDTH"] = "%1のわくのサイズ";
    Blockly.Msg["SETTER_BORDER_WIDTH"] = "%1のわくのサイズを%2にする";
    Blockly.Msg["SETTER_BORDER_WIDTH_BY_STEP"] =
      "%1のわくのサイズを%2ずつかえる";
    Blockly.Msg["SETTER_BORDER_WIDTH_BY_TIME"] =
      "%1びょうで%2のわくのサイズを%3にする";

    Blockly.Msg["GETTER_BORDER_RADIUS"] = "%1のかどのまるさ";
    Blockly.Msg["SETTER_BORDER_RADIUS"] = "%1のかどのまるさを%2にする";
    Blockly.Msg["SETTER_BORDER_RADIUS_BY_STEP"] =
      "%1のかどのまるさを%2ずつかえる";
    Blockly.Msg["SETTER_BORDER_RADIUS_BY_TIME"] =
      "%1びょうで%2のかどのまるさを%3にする";

    Blockly.Msg["GETTER_X_COORDINATE"] = "%1のXざひょう";
    Blockly.Msg["SETTER_X_COORDINATE"] = "%1のXざひょうを%2にする";
    Blockly.Msg["SETTER_X_COORDINATE_BY_STEP"] = "%1のXざひょうを%2ずつかえる";
    Blockly.Msg["SETTER_X_COORDINATE_BY_TIME"] =
      "%1びょうで%2のXざひょうを%3にする";
    Blockly.Msg["SETTER_X_COORDINATE_BY_STEP_TIME"] =
      "%1びょうで%2のXざひょうを%3ずつかえる";

    Blockly.Msg["GETTER_Y_COORDINATE"] = "%1のYざひょう";
    Blockly.Msg["SETTER_Y_COORDINATE"] = "%1のYざひょうを%2にする";
    Blockly.Msg["SETTER_Y_COORDINATE_BY_STEP"] = "%1のYざひょうを%2ずつかえる";
    Blockly.Msg["SETTER_Y_COORDINATE_BY_TIME"] =
      "%1びょうで%2のYざひょうを%3にする";
    Blockly.Msg["SETTER_Y_COORDINATE_BY_STEP_TIME"] =
      "%1びょうで%2のYざひょうを%3ずつかえる";
    Blockly.Msg["SETTER_X_Y_COORDINATE"] =
      "%1のXざひょうを%2、Yざひょうを%3にする";
    Blockly.Msg["SETTER_X_Y_BY_TIME"] =
      "%1びょうで%2のXざひょうを%3、Yざひょうを%4にする";
    Blockly.Msg["SETTER_X_Y_BY_STEP_TIME"] =
      "%1びょうで%2のXざひょうを%3、Yざひょうを%4ずつかえる";

    Blockly.Msg["GETTER_ROTATION"] = "%1のかくど";
    Blockly.Msg["SETTER_LEFT_ROTATION"] = "%1をひだりへ%2にむける";
    Blockly.Msg["SETTER_LEFT_ROTATION_BY_STEP"] = "%1をひだりへ%2ずつまわす";
    Blockly.Msg["SETTER_LEFT_ROTATION_BY_TIME"] =
      "%1びょうで%2をひだりへ%3にむける";
    Blockly.Msg["SETTER_RIGHT_ROTATION"] = "%1をみぎへ%2にむける";
    Blockly.Msg["SETTER_RIGHT_ROTATION_BY_STEP"] = "%1をみぎへ%2ずつまわす";
    Blockly.Msg["SETTER_RIGHT_ROTATION_BY_TIME"] =
      "%1びょうで%2をみぎへ%3にむける";

    Blockly.Msg["GETTER_SCALE"] = "%1のサイズ";
    Blockly.Msg["SETTER_SCALE"] = "%1のサイズを%2ばいにする";
    Blockly.Msg["SETTER_SCALE_BY_STEP"] = "%1のサイズを%2ばいずつかえる";
    Blockly.Msg["SETTER_SCALE_BY_TIME"] = "%1びょうで%2のサイズを%3ばいにする";

    Blockly.Msg["GETTER_SHADOW_COLOR"] = "%1のかげのいろ";
    Blockly.Msg["SETTER_SHADOW_COLOR"] = "%1のかげのいろを%2にする";
    Blockly.Msg["GETTER_SHADOW_RADIUS"] = "%1のかげのサイズ";
    Blockly.Msg["SETTER_SHADOW_RADIUS"] = "%1のかげのサイズを%2にする";
    Blockly.Msg["SETTER_SHADOW_RADIUS_BY_STEP"] =
      "%1のかげのサイズを%2ずつかえる";
    Blockly.Msg["SETTER_SHADOW_RADIUS_BY_TIME"] =
      "%1びょうで%2のかげのサイズを%3にする";

    Blockly.Msg["GETTER_WIDTH"] = "%1のよこのサイズ";
    Blockly.Msg["SETTER_WIDTH"] = "%1のよこのサイズを%2にする";
    Blockly.Msg["SETTER_WIDTH_BY_STEP"] = "%1のよこのサイズを%2ずつかえる";
    Blockly.Msg["SETTER_WIDTH_BY_TIME"] =
      "%1びょうで%2のよこのサイズを%3にする";

    Blockly.Msg["GETTER_HEIGHT"] = "%1のたてのサイズ";
    Blockly.Msg["SETTER_HEIGHT"] = "%1のたてのサイズを%2にする";
    Blockly.Msg["SETTER_HEIGHT_BY_STEP"] = "%1のたてのサイズを%2ずつかえる";
    Blockly.Msg["SETTER_HEIGHT_BY_TIME"] =
      "%1びょうで%2のたてのサイズを%3にする";
    Blockly.Msg["SETTER_WIDTH_HEIGHT_BY_TIME"] =
      "%1びょうで%2のよこのサイズを%3、たてのサイズを%4にする";

    Blockly.Msg["TEXT_FONT_WEIGHT_BOLD"] = "ふとい";
    Blockly.Msg["TEXT_FONT_WEIGHT_REGULAR"] = "ふつう";
    Blockly.Msg["TEXT_FONT_WEIGHT_LIGHT"] = "ほそい";

    Blockly.Msg["GETTER_TEXT_FONT_FAMILY"] = "%1のフォント";
    Blockly.Msg["SETTER_TEXT_FONT_FAMILY"] = "%1のフォントを%2にする";
    Blockly.Msg["GETTER_TEXT_FONT_WEIGHT"] = "%1のテキストウェイト";
    Blockly.Msg["SETTER_TEXT_FONT_WEIGHT"] = "%1のテキストウェイトを%2にする";
    Blockly.Msg["SETTER_TEXT_FONT_WEIGHT_BY_STEP"] =
      "%1のテキストウェイトを%2ずつかえる";
    Blockly.Msg["SETTER_TEXT_FONT_WEIGHT_BY_TIME"] =
      "%1びょうで%2のテキストウェイトを%3にする";
    Blockly.Msg["GETTER_TEXT_FONT_SIZE"] = "%1のフォントサイズ";
    Blockly.Msg["SETTER_TEXT_FONT_SIZE"] = "%1のフォントサイズを%2にする";
    Blockly.Msg["SETTER_TEXT_FONT_SIZE_BY_STEP"] =
      "%1のフォントサイズを%2ずつかえる";
    Blockly.Msg["SETTER_TEXT_FONT_SIZE_BY_TIME"] =
      "%1びょうで%2のフォントサイズを%3にする";
    Blockly.Msg["GETTER_TEXT"] = "%1のテキスト";
    Blockly.Msg["SETTER_TEXT"] = "%1のテキストを%2にする";
    Blockly.Msg["GETTER_TEXT_COLOR"] = "%1のテキストのいろ";
    Blockly.Msg["SETTER_TEXT_COLOR"] = "%1のテキストのいろを%2にする";
    Blockly.Msg["GETTER_TEXT_SHADOW_COLOR"] = "%1のテキストのかげのいろ";
    Blockly.Msg["SETTER_TEXT_SHADOW_COLOR"] =
      "%1のテキストのかげのいろを%2にする";
    Blockly.Msg["GETTER_TEXT_SHADOW_RADIUS"] = "%1のテキストのかげのサイズ";
    Blockly.Msg["SETTER_TEXT_SHADOW_RADIUS"] =
      "%1のテキストのかげのサイズを%2にする";
    Blockly.Msg["SETTER_TEXT_SHADOW_RADIUS_BY_STEP"] =
      "%1のテキストのかげのサイズを%2ずつ変える";
    Blockly.Msg["SETTER_TEXT_SHADOW_RADIUS_BY_TIME"] =
      "%1びょうで%2のテキストのかげのサイズを%3にする";
  } else if (locale === "ja_kanji") {
    Blockly.setLocale(Jp);
    Blockly.Msg["DELETE_X_BLOCKS"] = "ブロックを削除する";

    Blockly.Msg["DEFAULT_COMPONENT"] = "パーツ";
    Blockly.Msg["EVENT_CLICK"] = "がクリックされたとき";

    Blockly.Msg["SCREEN_EVENT_LOADING"] = "が表示されたとき";
    Blockly.Msg["SCREEN_EVENT_NAVIGATE"] = "へ移動";
    Blockly.Msg["SCREEN_GETTER_BACKGROUND_COLOR"] = "の背景の色";
    Blockly.Msg["SCREEN_SETTER_BACKGROUND_COLOR"] = "の背景の色を";

    Blockly.Msg["TOGGLE_GETTER_VALUE"] = "%1の値";
    Blockly.Msg["TOGGLE_SETTER_VALUE"] = "%1の値を%2にする";

    Blockly.Msg["CHECKBOX_EVENT_CHECK"] = "がチェックされたとき";
    Blockly.Msg["SWITCH_EVENT_VALUE_CHANGE"] = "のが変わった時";

    Blockly.Msg["TEXTINPUT_EVENT_VALUE_CHANGE"] = "が入力されたとき";
    Blockly.Msg["TEXTINPUT_GETTER_VALUE"] = "%1が入力された値";
    Blockly.Msg["TEXTINPUT_SETTER_VALUE"] = "%1の値を%2にする";

    Blockly.Msg["IMAGE_SETTER_SRC"] = "%1の画像を%2にする";

    Blockly.Msg["DISPLAY_ORDER"] = "の表示を";
    Blockly.Msg["DISPLAY_ORDER_TOP"] = "一番上";
    Blockly.Msg["DISPLAY_ORDER_BOTTOM"] = "一番下";

    Blockly.Msg["GETTER_OPACITY"] = "%1の透明度";
    Blockly.Msg["SETTER_OPACITY"] = "%1の透明度を%2にする";
    Blockly.Msg["SETTER_OPACITY_BY_STEP"] = "%1透明度を%2ずつ変える";
    Blockly.Msg["SETTER_OPACITY_BY_TIME"] = "%1秒で%2の透明度を%3にする";

    Blockly.Msg["GETTER_BACKGROUND_COLOR"] = "%1の背景の色";
    Blockly.Msg["SETTER_BACKGROUND_COLOR"] = "%1の背景の色を%2にする";

    Blockly.Msg["GETTER_BORDER_COLOR"] = "%1の枠の色";
    Blockly.Msg["SETTER_BORDER_COLOR"] = "%1の枠の色を%2にする";
    Blockly.Msg["GETTER_BORDER_WIDTH"] = "%1の枠のサイズ";
    Blockly.Msg["SETTER_BORDER_WIDTH"] = "%1の枠のサイズを%2にする";
    Blockly.Msg["SETTER_BORDER_WIDTH_BY_STEP"] = "%1の枠のサイズを%2ずつ変える";
    Blockly.Msg["SETTER_BORDER_WIDTH_BY_TIME"] =
      "%1秒で%2の枠のサイズを%3にする";

    Blockly.Msg["GETTER_BORDER_RADIUS"] = "%1の角の丸さ";
    Blockly.Msg["SETTER_BORDER_RADIUS"] = "%1の角の丸さを%2にする";
    Blockly.Msg["SETTER_BORDER_RADIUS_BY_STEP"] = "%1の角の丸さを%2ずつ変える";
    Blockly.Msg["SETTER_BORDER_RADIUS_BY_TIME"] =
      "%1秒で%2の角の丸さを%3にする";

    Blockly.Msg["GETTER_X_COORDINATE"] = "%1のX座標";
    Blockly.Msg["SETTER_X_COORDINATE"] = "%1のX座標を%2にする";
    Blockly.Msg["SETTER_X_COORDINATE_BY_STEP"] = "%1のX座標を%2ずつ変える";
    Blockly.Msg["SETTER_X_COORDINATE_BY_TIME"] = "%1秒で%2のX座標を%3にする";
    Blockly.Msg["SETTER_X_COORDINATE_BY_STEP_TIME"] =
      "%1秒で%2のX座標を%3ずつ変える";

    Blockly.Msg["GETTER_Y_COORDINATE"] = "%1のY座標";
    Blockly.Msg["SETTER_Y_COORDINATE"] = "%1のY座標を%2にする";
    Blockly.Msg["SETTER_Y_COORDINATE_BY_STEP"] = "%1のY座標を%2ずつ変える";
    Blockly.Msg["SETTER_Y_COORDINATE_BY_TIME"] = "%1秒で%2のY座標を%3にする";
    Blockly.Msg["SETTER_Y_COORDINATE_BY_STEP_TIME"] =
      "%1秒で%2のY座標を%3ずつ変える";
    Blockly.Msg["SETTER_X_Y_COORDINATE"] = "%1のX座標を%2、Y座標を%3にする";
    Blockly.Msg["SETTER_X_Y_BY_TIME"] = "%1秒で%2のX座標を%3、Y座標を%4にする";
    Blockly.Msg["SETTER_X_Y_BY_STEP_TIME"] =
      "%1秒で%2のX座標を%3、Y座標を%4ずつ変える";

    Blockly.Msg["GETTER_ROTATION"] = "%1の角度";
    Blockly.Msg["SETTER_LEFT_ROTATION"] = "%1を左へ%2に向ける";
    Blockly.Msg["SETTER_LEFT_ROTATION_BY_STEP"] = "%1を左へ%2ずつ回す";
    Blockly.Msg["SETTER_LEFT_ROTATION_BY_TIME"] = "%1秒で%2を左へ%3に向ける";
    Blockly.Msg["SETTER_RIGHT_ROTATION"] = "%1を右へ%2に向ける";
    Blockly.Msg["SETTER_RIGHT_ROTATION_BY_STEP"] = "%1を右へ%2ずつ回す";
    Blockly.Msg["SETTER_RIGHT_ROTATION_BY_TIME"] = "%1秒で%2を右へ%3に向ける";

    Blockly.Msg["GETTER_SCALE"] = "%1のサイズ";
    Blockly.Msg["SETTER_SCALE"] = "%1のサイズを%2倍にする";
    Blockly.Msg["SETTER_SCALE_BY_STEP"] = "%1のサイズを%2倍ずつ変える";
    Blockly.Msg["SETTER_SCALE_BY_TIME"] = "%1秒で%2のサイズを%3倍にする";

    Blockly.Msg["GETTER_SHADOW_COLOR"] = "%1の影の色";
    Blockly.Msg["SETTER_SHADOW_COLOR"] = "%1の影の色を%2にする";
    Blockly.Msg["GETTER_SHADOW_RADIUS"] = "%1の影のサイズ";
    Blockly.Msg["SETTER_SHADOW_RADIUS"] = "%1の影のサイズを%2にする";
    Blockly.Msg["SETTER_SHADOW_RADIUS_BY_STEP"] =
      "%1の影のサイズを%2ずつ変える";
    Blockly.Msg["SETTER_SHADOW_RADIUS_BY_TIME"] =
      "%1秒で%2の影のサイズを%3にする";

    Blockly.Msg["GETTER_WIDTH"] = "%1の横のサイズ";
    Blockly.Msg["SETTER_WIDTH"] = "%1の横のサイズを%2にする";
    Blockly.Msg["SETTER_WIDTH_BY_STEP"] = "%1の横のサイズを%2ずつ変える";
    Blockly.Msg["SETTER_WIDTH_BY_TIME"] = "%1秒で%2の横のサイズを%3にする";

    Blockly.Msg["GETTER_HEIGHT"] = "%1の縦のサイズ";
    Blockly.Msg["SETTER_HEIGHT"] = "%1の縦のサイズを%2にする";
    Blockly.Msg["SETTER_HEIGHT_BY_STEP"] = "%1の縦のサイズを%2ずつ変える";
    Blockly.Msg["SETTER_HEIGHT_BY_TIME"] = "%1秒で%2の縦のサイズを%3にする";
    Blockly.Msg["SETTER_WIDTH_HEIGHT_BY_TIME"] =
      "%1秒で%2の横サイズを%3、縦サイズを%4にする";

    Blockly.Msg["TEXT_FONT_WEIGHT_BOLD"] = "太い";
    Blockly.Msg["TEXT_FONT_WEIGHT_REGULAR"] = "普通";
    Blockly.Msg["TEXT_FONT_WEIGHT_LIGHT"] = "細い";

    Blockly.Msg["GETTER_TEXT_FONT_FAMILY"] = "%1のフォント";
    Blockly.Msg["SETTER_TEXT_FONT_FAMILY"] = "%1のフォントを%2にする";
    Blockly.Msg["GETTER_TEXT_FONT_WEIGHT"] = "%1のテキストウェイト";
    Blockly.Msg["SETTER_TEXT_FONT_WEIGHT"] = "%1のテキストウェイトを%2にする";
    Blockly.Msg["SETTER_TEXT_FONT_WEIGHT_BY_STEP"] =
      "%1のテキストウェイトを%2ずつ変える";
    Blockly.Msg["SETTER_TEXT_FONT_WEIGHT_BY_TIME"] =
      "%1秒で%2のテキストウェイトを%3にする";
    Blockly.Msg["GETTER_TEXT_FONT_SIZE"] = "%1のフォントサイズ";
    Blockly.Msg["SETTER_TEXT_FONT_SIZE"] = "%1のフォントサイズを%2にする";
    Blockly.Msg["SETTER_TEXT_FONT_SIZE_BY_STEP"] =
      "%1のフォントサイズを%2ずつ変える";
    Blockly.Msg["SETTER_TEXT_FONT_SIZE_BY_TIME"] =
      "%1秒で%2のフォントサイズを%3にする";
    Blockly.Msg["GETTER_TEXT"] = "%1のテキスト";
    Blockly.Msg["SETTER_TEXT"] = "%1のテキストを%2にする";
    Blockly.Msg["GETTER_TEXT_COLOR"] = "%1のテキストの色";
    Blockly.Msg["SETTER_TEXT_COLOR"] = "%1のテキストの色を%2にする";
    Blockly.Msg["GETTER_TEXT_SHADOW_COLOR"] = "%1のテキストの影の色";
    Blockly.Msg["SETTER_TEXT_SHADOW_COLOR"] = "%1のテキストの影の色を%2にする";
    Blockly.Msg["GETTER_TEXT_SHADOW_RADIUS"] = "%1のテキストの影のサイズ";
    Blockly.Msg["SETTER_TEXT_SHADOW_RADIUS"] =
      "%1のテキストの影のサイズを%2にする";
    Blockly.Msg["SETTER_TEXT_SHADOW_RADIUS_BY_STEP"] =
      "%1のテキストの影のサイズを%2ずつ変える";
    Blockly.Msg["SETTER_TEXT_SHADOW_RADIUS_BY_TIME"] =
      "%1秒で%2のテキストの影のサイズを%3にする";
  }
};
