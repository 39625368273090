import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;

  return (
    <svg
      id={id}
      preserveAspectRatio="none"
      viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${
        property.style.layout.width + SizeOffset
      } ${property.style.layout.height + SizeOffset}`}
    >
      <ellipse
        paintOrder="stroke"
        cx={property.style.layout.width / 2}
        cy={property.style.layout.height / 2}
        rx={property.style.layout.width / 2}
        ry={property.style.layout.height / 2}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        fill={property.style.view.backgroundColor}
      />
    </svg>
  );
};
export default WrapComponent;
