import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import {
  sizeTimeBlockGen,
  BlocklyJsOperator,
  initPropertyGetterBlock,
  initPropertySetterBlock,
  initPropertySetterByTimeBlock,
} from "common/blockly";
import {
  WIDTH_MIN,
  WIDTH_MAX,
  HEIGHT_MIN,
  HEIGHT_MAX,
} from "common/components";

export const InitSizePropertyBlock = (
  components: string[][],
  defaultComponent?: string | undefined
) => {
  function showEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      components.length === 0 ? [["パーツ", ""]] : components,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  Blockly.Blocks["component_property_getter_width"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_width",
          "%{BKY_GETTER_WIDTH}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      // const filed = this.getField("component_id") as Blockly.FieldDropdown;
      // filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_width"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.get${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Width("${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };

  Blockly.Blocks["component_property_setter_width"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_width",
          "%{BKY_SETTER_WIDTH}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > WIDTH_MAX) {
              return WIDTH_MAX;
            } else if (value < WIDTH_MIN) {
              return WIDTH_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_width"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Size("${component[1]}", ${value}, undefined);\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_width_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_width_by_step",
          "%{BKY_SETTER_WIDTH_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > WIDTH_MAX) {
              return WIDTH_MAX;
            } else if (value < -WIDTH_MAX) {
              return -WIDTH_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_width_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }SizeByStep("${component[1]}", ${value}, undefined);\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_width_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_width_by_time",
          "%{BKY_SETTER_WIDTH_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > WIDTH_MAX) {
              return WIDTH_MAX;
            } else if (value < WIDTH_MIN) {
              return WIDTH_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_width_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }SizeByTime("${component[1]}", ${time}, ${value}, undefined);\n`;

    return code;
  };

  Blockly.Blocks["component_property_getter_height"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_height",
          "%{BKY_GETTER_HEIGHT}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_height"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.get${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Height("${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };

  Blockly.Blocks["component_property_setter_height"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_height",
          "%{BKY_SETTER_HEIGHT}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > HEIGHT_MAX) {
              return HEIGHT_MAX;
            } else if (value < HEIGHT_MIN) {
              return HEIGHT_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_height"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Size("${component[1]}", undefined, ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_height_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_height_by_step",
          "%{BKY_SETTER_HEIGHT_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > HEIGHT_MAX) {
              return HEIGHT_MAX;
            } else if (value < -HEIGHT_MAX) {
              return -HEIGHT_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_height_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }SizeByStep("${component[1]}", undefined, ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_height_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_height_by_time",
          "%{BKY_SETTER_HEIGHT_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > HEIGHT_MAX) {
              return HEIGHT_MAX;
            } else if (value < HEIGHT_MIN) {
              return HEIGHT_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_height_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }SizeByTime("${component[1]}", ${time}, undefined, ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_width_height_by_time"] = {
    init: function () {
      this.jsonInit(
        sizeTimeBlockGen(
          `component_property_setter_width_height_by_time`,
          components.length === 0 ? [["パーツ", ""]] : components
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputWidhtTarget = this.getInputTargetBlock(
          "width"
        ) as Blockly.Block;
        if (inputWidhtTarget && inputWidhtTarget.getField("NUM")) {
          inputWidhtTarget.getField("NUM").setValidator((value: number) => {
            if (value > WIDTH_MAX) {
              return WIDTH_MAX;
            } else if (value < WIDTH_MIN) {
              return WIDTH_MIN;
            } else {
              return value;
            }
          });
        }
        const inputHeightTarget = this.getInputTargetBlock(
          "height"
        ) as Blockly.Block;
        if (inputHeightTarget && inputHeightTarget.getField("NUM")) {
          inputHeightTarget.getField("NUM").setValidator((value: number) => {
            if (value > HEIGHT_MAX) {
              return HEIGHT_MAX;
            } else if (value < HEIGHT_MIN) {
              return HEIGHT_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_width_height_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const width = BlocklyJs.valueToCode(
      block,
      "width",
      BlocklyJsOperator.ORDER_NONE
    );
    const height = BlocklyJs.valueToCode(
      block,
      "height",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }SizeByTime("${component[1]}", ${time}, ${width}, ${height});\n`;
    return code;
  };
};
