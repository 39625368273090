import i18n from "i18n-js";
import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;

  return (
    <div
      style={{
        width: property.style.layout.width,
        height: property.style.layout.height,
        backfaceVisibility: "hidden",
        WebkitBackfaceVisibility: "hidden",
        transform: `translateZ(0) scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <p
        style={{
          userSelect: "none",
          fontFamily: property.style.text.fontFamily,
          letterSpacing: property.style.text.letterSpacing,
          fontSize: property.style.text.fontSize,
          margin: 0,
          textAlign: "center",
          verticalAlign: "middle",
          whiteSpace: "pre-line",
          color: property.style.text.color,
        }}
      >
        {i18n.t(property.text)}
      </p>
    </div>
  );
};

export default WrapComponent;
