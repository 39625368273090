import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  EventsEntity,
  ScreensEntity,
  BlocklyEntity,
  PropertiesEntity,
  PreviewInitialStateType,
} from "features/preview/types";
import {
  SCREEN_SIZE,
  DEFAULT_SCREEN_ORIENTATION,
} from "features/preview/constants";
import { getUserProjectContent } from "features/preview/api";

export const screensAdapter = createEntityAdapter<ScreensEntity>({
  sortComparer: (a, b) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  },
});
export const propertiesAdapter = createEntityAdapter<PropertiesEntity>();
export const blocklyAdapter = createEntityAdapter<BlocklyEntity>({
  selectId: (blockly) => blockly.screenId,
});
export const eventsAdapter = createEntityAdapter<EventsEntity>();

export const {
  selectAll: selectAllScreens,
  selectById: selectSceenById,
  selectIds: selectScreentIds,
} = screensAdapter.getSelectors((state: RootState) => state.preview.screens);

export const {
  selectAll: selectAllProperties,
  selectById: selectPropertyById,
  selectIds: selectPropertyIds,
} = propertiesAdapter.getSelectors(
  (state: RootState) => state.preview.properties
);

export const {
  selectAll: selectAllBlockly,
  selectById: selectBlocklyById,
  selectIds: selectBlocklyIds,
} = blocklyAdapter.getSelectors((state: RootState) => state.preview.blockly);

export const {
  selectAll: selectAllEvents,
  selectById: selectEventById,
  selectIds: selectEventIds,
} = eventsAdapter.getSelectors((state: RootState) => state.preview.events);

export const initialState: PreviewInitialStateType = {
  screenSize: {
    width: SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].WIDTH,
    height: SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].HEIGHT,
    orientation: DEFAULT_SCREEN_ORIENTATION,
  },
  screens: screensAdapter.getInitialState(),
  properties: propertiesAdapter.getInitialState(),
  blockly: blocklyAdapter.getInitialState(),
  events: eventsAdapter.getInitialState(),
};
const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    updateEvents(
      state: PreviewInitialStateType,
      action: PayloadAction<EventsEntity[]>
    ) {
      action.payload.forEach((event) => {
        eventsAdapter.upsertOne(state.events, event);
      });
    },
    removeAllEvents(state: PreviewInitialStateType) {
      eventsAdapter.removeAll(state.events);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUserProjectContent.fulfilled,
      (state: PreviewInitialStateType, action: PayloadAction<any>) => {
        const { content } = action.payload;
        state.defaultScreenId = content.defaultScreenId;
        state.screenSize = content.screenSize;
        screensAdapter.setAll(state.screens, content.screens);
        propertiesAdapter.setAll(state.properties, content.properties);
        blocklyAdapter.setAll(state.blockly, content.blockly);
        eventsAdapter.removeAll(state.events);
      }
    );
  },
});
export const actions = { ...previewSlice.actions };
export default previewSlice.reducer;
