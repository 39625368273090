/* eslint-disable no-new-func */
import chroma from "chroma-js";
import * as codeGen from "common/blockly/codeGen";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property, navigate, events, proceduresCode } = props;

  const onValueChangeEvents = events
    .filter((event) =>
      event.eventId.includes(`${id}_checkbox_value_change_event`)
    )
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnValueChange = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  const textShadowColor = chroma(property.style.text.textShadowColor).rgba();
  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();

  return (
    <RuntimeContainer {...props}>
      <div
        id={`checkbox-shape-container-${id}`}
        className="flex-row-center w-full h-full cursor-pointer"
        style={{
          filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
            property.style.shadow.shadowOffset.height
          }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
          ${shadowColor[1]},
          ${shadowColor[2]},
          ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
        ))`,
        }}
      >
        <input
          id={`checkbox-${id}`}
          type="checkbox"
          className="m-0 mr-[3px]"
          style={{
            backfaceVisibility: "hidden",
            width: property.style.text.fontSize,
            height: property.style.text.fontSize,
          }}
          onChange={handleOnValueChange(onValueChangeEvents, navigate, codeGen)}
        />
        <label
          id={`checkbox-text-${id}`}
          className="align-middle"
          style={{
            color: property.style.text.color,
            fontFamily: property.style.text.fontFamily,
            fontSize: property.style.text.fontSize,
            letterSpacing: property.style.text.letterSpacing,
            textShadow: `${property.style.text.textShadowOffset.width}px ${
              property.style.text.textShadowOffset.height
            }px ${property.style.text.textShadowRadius}px rgba(${
              textShadowColor[0]
            },
              ${textShadowColor[1]},
              ${textShadowColor[2]},
              ${
                property.style.text.textShadowRadius === 0
                  ? 0
                  : textShadowColor[3]
              }
            )`,
          }}
        >
          {property.text}
        </label>
      </div>
    </RuntimeContainer>
  );
};

export default WrapComponent;
