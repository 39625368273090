/* eslint-disable no-new-func */
import chroma from "chroma-js";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, typeId, zIndex, property, children } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;
  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();

  return (
    <div
      id={`shape-container-${id}`}
      data-type-id={typeId}
      className="flex-col-view !absolute left-0 top-0"
      style={{
        zIndex: zIndex,
        backfaceVisibility: "hidden",
        transform: `translateX(${property.style.transform.translateX}px) translateY(${property.style.transform.translateY}px) rotate(${property.style.transform.rotation}deg)`,
        willChange: "transform",
        opacity: property.style.view.opacity / 100,
        filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
          property.style.shadow.shadowOffset.height
        }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
          ${shadowColor[1]},
          ${shadowColor[2]},
          ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
        ))`,
      }}
    >
      <svg
        id={`shape-svg-${id}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width + SizeOffset}
        height={height + SizeOffset}
        viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${width + SizeOffset} ${
          height + SizeOffset
        }`}
      >
        {children}
      </svg>
    </div>
  );
};

export default WrapComponent;
