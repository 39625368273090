import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { ComponentManager } from "common/components";
import { EventsEntity } from "features/preview/types";
import { selectPropertyById } from "features/preview/slice";

export const RuntimeView = (props: {
  id: string;
  zIndex: number;
  navigate: (id: string) => void;
  events: EventsEntity[];
  initVariables: string;
  proceduresCode: string;
}) => {
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, props.id)
  );
  const { typeId, property } = propertyEntity;
  const RuntimeViewComponent =
    ComponentManager[typeId].component.RuntimeComponent;
  return (
    <RuntimeViewComponent {...props} typeId={typeId} property={property} />
  );
};
