/* eslint-disable no-new-func */
import chroma from "chroma-js";
import * as codeGen from "common/blockly/codeGen";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property, navigate, events, proceduresCode } = props;
  const PADDING_VERTICAL = 10;
  const PADDING_HORIZONTAL = 10;
  const size = Math.min(
    property.style.layout.width - PADDING_HORIZONTAL * 2,
    property.style.layout.height - PADDING_VERTICAL * 2
  );

  const onValueChangeEvents = events
    .filter((event) =>
      event.eventId.includes(`${id}_textinput_value_change_event`)
    )
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnValueChange = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();
  const textShadowColor = chroma(property.style.text.textShadowColor).rgba();
  return (
    <RuntimeContainer {...props}>
      <div
        id={`textinput-shape-container-${id}`}
        className="w-full h-full flex-row-center"
        style={{
          filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
            property.style.shadow.shadowOffset.height
          }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
          ${shadowColor[1]},
          ${shadowColor[2]},
          ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
        ))`,
        }}
      >
        <input
          id={`textinput-text-${id}`}
          className="!m-0"
          data-border-radius={property.style.view.borderRadius}
          data-padding-vertical={PADDING_VERTICAL}
          data-padding-horizontal={PADDING_HORIZONTAL}
          type="text"
          autoFocus={false}
          placeholder={property.placeholder}
          defaultValue={property.text}
          style={{
            paddingLeft: PADDING_HORIZONTAL,
            paddingRight: PADDING_HORIZONTAL,
            paddingTop: PADDING_VERTICAL,
            paddingBottom: PADDING_VERTICAL,
            width: property.style.layout.width - PADDING_HORIZONTAL * 2,
            height: property.style.layout.height - PADDING_VERTICAL * 2,
            boxSizing: "content-box",
            borderStyle: property.style.view.borderStyle,
            borderColor: property.style.view.borderColor,
            borderWidth: property.style.view.borderWidth / 2,
            borderRadius: size * (property.style.view.borderRadius / 200),
            color: property.style.text.color,
            fontFamily: property.style.text.fontFamily,
            fontSize: property.style.text.fontSize,
            letterSpacing: property.style.text.letterSpacing,
            backgroundColor: property.style.view.backgroundColor,
            textShadow: `${property.style.text.textShadowOffset.width}px ${
              property.style.text.textShadowOffset.height
            }px ${property.style.text.textShadowRadius}px rgba(${
              textShadowColor[0]
            },
              ${textShadowColor[1]},
              ${textShadowColor[2]},
              ${
                property.style.text.textShadowRadius === 0
                  ? 0
                  : textShadowColor[3]
              }
            )`,
          }}
          onChange={handleOnValueChange(onValueChangeEvents, navigate, codeGen)}
        />
      </div>
    </RuntimeContainer>
  );
};

export default WrapComponent;
