import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getStampShadowColor = (id: string) =>
  getShadowColor(`stamp-shape-container-${id}`);
export const setStampShadowColor = (id: string, value: string) =>
  setShadowColor(`stamp-shape-container-${id}`, value);

export const getStampShadowRadius = (id: string) =>
  getShadowRadius(`stamp-shape-container-${id}`);
export const setStampShadowRadius = (id: string, value: number) =>
  setShadowRadius(`stamp-shape-container-${id}`, value);
export const setStampShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`stamp-shape-container-${id}`, value);
export const setStampShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`stamp-shape-container-${id}`, time, value);
