import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitScreenEventBlock = (
  selectedScreen: string[][],
  allScreens: string[][]
) => {
  function selectedShowEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      selectedScreen,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  function showEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      allScreens,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  Blockly.Blocks["screen_loading_event"] = {
    init: function () {
      const componentsField = new Blockly.FieldDropdown(
        selectedScreen.length === 0 ? [["パーツ", ""]] : selectedScreen
      );
      componentsField.showEditor = selectedShowEditor;

      this.appendDummyInput()
        .appendField(componentsField, "component_id")
        .appendField("%{BKY_SCREEN_EVENT_LOADING}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };

  BlocklyJs["screen_loading_event"] = function (block) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const code = `${logic}\n`;
    return code;
  };

  Blockly.Blocks["screen_click_event"] = {
    init: function () {
      const componentsField = new Blockly.FieldDropdown(
        selectedScreen.length === 0 ? [["パーツ", ""]] : selectedScreen
      );
      componentsField.showEditor = selectedShowEditor;

      this.appendDummyInput()
        .appendField(componentsField, "component_id")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["screen_click_event"] = function (block) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const code = `${logic}\n`;
    return code;
  };

  Blockly.Blocks["screen_navigate_event"] = {
    init: function () {
      const screenField = new Blockly.FieldDropdown(() =>
        allScreens.length === 0 ? [["パーツ", ""]] : allScreens
      );
      screenField.showEditor = showEditor;

      this.appendDummyInput()
        .appendField(screenField, "component_id")
        .appendField("%{BKY_SCREEN_EVENT_NAVIGATE}");
      this.setPreviousStatement(true);
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["screen_navigate_event"] = function (block: Blockly.BlockSvg) {
    const component = block.getField("component_id").getValue().split("_");
    const code = `navigate("${component[1]}");`;
    return code;
  };
};
