import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitVariableBlock = () => {
  BlocklyJs["variables_set"] = function (block: Blockly.BlockSvg) {
    // Variable setter.
    const argument0 =
      BlocklyJs.valueToCode(block, "VALUE", BlocklyJs.ORDER_ASSIGNMENT) || "0";
    const varName = BlocklyJs.nameDB_.getName(
      block.getFieldValue("VAR"),
      Blockly.VARIABLE_CATEGORY_NAME
    );
    return `\n${varName} = ${argument0};\n\n`;
  };
};

export const InitProceduresCallBlock = () => {
  BlocklyJs["procedures_callreturn"] = function (block: Blockly.Block) {
    const funcName = BlocklyJs.nameDB_.getName(
      block.getFieldValue("NAME"),
      Blockly.PROCEDURE_CATEGORY_NAME
    );
    const args = [];
    const variables = block.getVars();
    for (var i = 0; i < variables.length; i++) {
      args[i] =
        BlocklyJs.valueToCode(
          block,
          "ARG" + i,
          (Blockly as any).JavaScript.ORDER_NONE
        ) || "null";
    }
    const code = `await ${funcName}(${args.join(", ")})`;
    return [code, (Blockly as any).JavaScript.ORDER_FUNCTION_CALL];
  };
};

export const InitMathInitBlock = () => {
  BlocklyJs["math_random_int"] = function (block: Blockly.Block) {
    const argument0 =
      BlocklyJs.valueToCode(
        block,
        "FROM",
        (Blockly as any).JavaScript.ORDER_NONE
      ) || "0";
    const argument1 =
      BlocklyJs.valueToCode(
        block,
        "TO",
        (Blockly as any).JavaScript.ORDER_NONE
      ) || "0";

    let a: number, b: number;
    if (Number(argument0) > Number(argument1)) {
      a = Number(argument1);
      b = Number(argument0);
    } else {
      a = Number(argument0);
      b = Number(argument1);
    }

    const code = `Math.floor(Math.random() * (${b} - ${a} + 1) + ${a})`;
    return [code, (Blockly as any).JavaScript.ORDER_FUNCTION_CALL];
  };
};

export const InitColorBlock = () => {
  Blockly.Blocks["component_property_colour_picker"] = {
    init: function () {
      const field = new Blockly.FieldColour();

      field.showEditor = function (e) {
        e.stopPropagation();
        e.preventDefault();
        Blockly.dialog.colour(
          this.getValue(),
          { x: e.x, y: e.y },
          function (color: string) {
            if (color !== null) {
              this.setValue(color);
            }
          }.bind(this)
        );
      };

      this.appendDummyInput().appendField(field, "COLOUR");
      this.setOutput(true, "Colour");
    },
  };
  BlocklyJs["component_property_colour_picker"] = function (
    block: Blockly.Block
  ) {
    const code = (Blockly as any).JavaScript.quote_(
      block.getFieldValue("COLOUR")
    );
    return [code, (Blockly as any).JavaScript.ORDER_ATOMIC];
  };
};
