import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";

export const getSwitchWidth = (id: string) => {
  const container = $(`#switch-container-${id}`);
  const svg = $(`#switch-svg-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  return currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
};
export const getSwitchHeight = (id: string) => {
  const container = $(`#switch-container-${id}`);
  const svg = $(`#switch-svg-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerHeight = container.height();
  return currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;
};

export const setSwitchSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#switch-container-${id}`);
  const svg = $(`#switch-svg-${id}`);
  const shape = $(`#switch-shape-${id}`);
  const track = $(`#switch-shape-track-${id}`);
  const thumb = $(`#switch-shape-thumb-${id}`);

  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);
  const borderRadius = Number(shape.data("borderRadius"));
  const switchValue = svg.data("value");

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const MARGIN =
    newShapeSize.x < newShapeSize.y
      ? newShapeSize.x * 0.1
      : newShapeSize.y * 0.1;
  const THUMB_BORDER_WIDTH = currentBorderWidth * 0.1;
  const THUMB_RADIUS =
    newShapeSize.x < newShapeSize.y
      ? newShapeSize.x * 0.4
      : newShapeSize.y * 0.4;

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);

  svg.attr(
    "viewBox",
    `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`
  );

  const size = Math.min(newShapeSize.x, newShapeSize.y);

  shape.attr("width", `${newShapeSize.x}`);
  shape.attr("height", `${newShapeSize.y}`);
  shape.attr("rx", `${size * (borderRadius / 200)}`);

  track.data("trackMargin", MARGIN);
  track.attr("x", `${MARGIN}`);
  track.attr("y", `${MARGIN}`);
  track.attr("width", `${newShapeSize.x - MARGIN * 2}`);
  track.attr("height", `${newShapeSize.y - MARGIN * 2}`);
  track.attr("rx", `${(size - MARGIN * 2) * (borderRadius / 200)}`);

  thumb.attr("cx", `${THUMB_RADIUS}`);
  thumb.attr("cy", `${THUMB_RADIUS + MARGIN}`);
  thumb.attr("r", `${THUMB_RADIUS}`);
  thumb.attr(
    "transform",
    `translate(${
      switchValue
        ? newShapeSize.x - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
        : MARGIN + THUMB_BORDER_WIDTH / 2
    } 0)`
  );
};

export const setSwitchSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const container = $(`#switch-container-${id}`);
  const svg = $(`#switch-svg-${id}`);

  const viewBox = svg.attr("viewBox").split(" ");

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const newShapeWidth =
    widthStep === undefined ? undefined : currentShapeWidth + widthStep;
  const newShapeHeight =
    heightStep === undefined ? undefined : currentShapeHeight + heightStep;

  setSwitchSize(id, newShapeWidth, newShapeHeight);
};

export const setSwitchSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#switch-container-${id}`);
  const svg = $(`#switch-svg-${id}`);
  const shape = $(`#switch-shape-${id}`);
  const track = $(`#switch-shape-track-${id}`);

  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);
  const borderRadius = Number(svg.data("borderRadius"));
  const switchValue = svg.data("value");

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  const MARGIN =
    newShapeSize.x < newShapeSize.y
      ? newShapeSize.x * 0.1
      : newShapeSize.y * 0.1;
  const THUMB_BORDER_WIDTH = currentBorderWidth * 0.1;
  const THUMB_RADIUS =
    newShapeSize.x < newShapeSize.y
      ? newShapeSize.x * 0.4
      : newShapeSize.y * 0.4;

  const size = Math.min(newShapeSize.x, newShapeSize.y);
  track.data("trackMargin", MARGIN);
  return Promise.all([
    anime({
      targets: `#switch-container-${id}`,
      translateX: `-=${offset.x}`,
      translateY: `+=${offset.y}`,
      width: newContainerSize.x,
      height: newContainerSize.y,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-svg-${id}`,
      viewBox: `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-shape-${id}`,
      width: newShapeSize.x,
      height: newShapeSize.y,
      rx: size * (borderRadius / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-shape-track-${id}`,
      x: MARGIN,
      y: MARGIN,
      width: newShapeSize.x - MARGIN * 2,
      height: newShapeSize.y - MARGIN * 2,
      rx: (size - MARGIN * 2) * (borderRadius / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-shape-thumb-${id}`,
      cx: THUMB_RADIUS,
      cy: THUMB_RADIUS + MARGIN,
      r: THUMB_RADIUS,
      transform: `translate(${
        switchValue
          ? newShapeSize.x - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
          : MARGIN + THUMB_BORDER_WIDTH / 2
      } 0)`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
