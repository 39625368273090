import $ from "jquery";
import anime from "animejs";
import {
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BORDER_RADIUS_MAX,
  BORDER_RADIUS_MIN,
} from "common/components";

export const getButtonBorderColor = (id: string) =>
  $(`#button-shape-${id}`).attr("stroke");
export const setButtonBorderColor = (id: string, value: string) =>
  $(`#button-shape-${id}`).attr("stroke", `${value}`);

export const getButtonBorderWidth = (id: string): number =>
  Number($(`#button-shape-${id}`).attr("stroke-width").split("px")[0]);
export const setButtonBorderWidth = (id: string, value: number) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const shape = $(`#button-shape-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const viewBox = svg.attr("viewBox").split(" ");
  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }

  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  svg.attr(
    "viewBox",
    `${Number(viewBox[0]) - delta / 2} ${Number(viewBox[1]) - delta / 2} ${
      Number(viewBox[2]) + delta
    } ${Number(viewBox[3]) + delta}`
  );
  shape.attr("stroke-width", `${currentBorderWidth + delta}`);
};

export const setButtonBorderWidthByStep = (id: string, value: number) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const shape = $(`#button-shape-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);

  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  svg.attr(
    "viewBox",
    `${Number(viewBox[0]) - delta / 2} ${Number(viewBox[1]) - delta / 2} ${
      Number(viewBox[2]) + delta
    } ${Number(viewBox[3]) + delta}`
  );
  shape.attr("stroke-width", `${currentBorderWidth + delta}`);
};
export const setButtonBorderWidthByTime = (
  id: string,
  time: number,
  value: number
) => {
  const svg = $(`#button-svg-${id}`);
  const shape = $(`#button-shape-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentBorderWidth = Number(shape.css("stroke-width").split("px")[0]);

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }

  const delta = target - currentBorderWidth;
  return Promise.all([
    anime({
      targets: `#button-container-${id}`,
      width: `+=${delta}`,
      height: `+=${delta}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#button-svg-${id}`,
      viewBox: `${Number(viewBox[0]) - delta / 2} ${
        Number(viewBox[1]) - delta / 2
      } ${Number(viewBox[2]) + delta} ${Number(viewBox[3]) + delta}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#button-shape-${id}`,
      strokeWidth: target,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};

export const getButtonBorderRadius = (id: string): number =>
  Number($(`#button-shape-${id}`).data("borderRadius"));
export const setButtonBorderRadius = (id: string, value: number) => {
  const shape = $(`#button-shape-${id}`);
  const currentShapeWidth = shape.width();
  const currentShapeHeight = shape.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  shape.data("borderRadius", target);
  shape.attr("rx", size * (target / 200));
};

export const setButtonBorderRadiusByStep = (id: string, value: number) => {
  const newBorderRadius =
    Number($(`#button-shape-${id}`).data("borderRadius")) + value;
  setButtonBorderRadius(id, newBorderRadius);
};
export const setButtonBorderRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const shape = $(`#button-shape-${id}`);
  const currentShapeWidth = shape.width();
  const currentShapeHeight = shape.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  shape.data("borderRadius", target);
  return Promise.all([
    anime({
      targets: `#button-shape-${id}`,
      rx: size * (target / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
