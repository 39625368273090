import chroma from "chroma-js";
import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;
  const width = property.style.layout.width * scale;
  const height = property.style.layout.height * scale;
  const borderWidth = property.style.view.borderWidth * scale;
  const MARGIN = width < height ? width * 0.1 : height * 0.1;
  const THUMB_BORDER_WIDTH = borderWidth * 0.1;
  const THUMB_RADIUS = width < height ? width * 0.4 : height * 0.4;

  const thumbColorHsl = chroma(property.style.view.backgroundColor).hsl();
  const thumbColorRgba = chroma(property.style.view.backgroundColor).rgba();
  const trackColor = chroma
    .hsl(thumbColorHsl[0], thumbColorHsl[1], thumbColorHsl[2] + 0.1)
    .rgba();
  const size = Math.min(width, height);
  return (
    <svg
      width={width + borderWidth}
      height={height + borderWidth}
      viewBox={`${-borderWidth / 2} ${-borderWidth / 2} ${
        width + borderWidth
      } ${height + borderWidth}`}
    >
      <rect
        x={0}
        y={0}
        rx={size * (property.style.view.borderRadius / 200)}
        width={width}
        height={height}
        paintOrder="stroke"
        strokeWidth={property.style.view.borderWidth}
        stroke={property.style.view.borderColor}
        fill="white"
      />
      <rect
        x={MARGIN}
        y={MARGIN}
        rx={(size - MARGIN * 2) * (property.style.view.borderRadius / 200)}
        width={width - MARGIN * 2}
        height={height - MARGIN * 2}
        paintOrder="stroke"
        fill={`rgba(${trackColor[0]}, ${trackColor[1]}, ${trackColor[2]}, ${thumbColorRgba[3]})`}
      />
      <circle
        cx={THUMB_RADIUS}
        cy={THUMB_RADIUS + MARGIN}
        r={THUMB_RADIUS}
        paintOrder="stroke"
        fill={String(property.style.view.backgroundColor)}
        strokeWidth={THUMB_BORDER_WIDTH}
        stroke={property.style.view.borderColor}
        transform={`translate(${
          width - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
        } 0)`}
      />
    </svg>
  );
};

export default WrapComponent;
