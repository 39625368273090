import $ from "jquery";
import anime from "animejs";
import {
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BORDER_RADIUS_MAX,
  BORDER_RADIUS_MIN,
} from "common/components";

export const getImageBorderColor = (id: string) =>
  $(`#image-${id}`).css("border-color");
export const setImageBorderColor = (id: string, value: string) =>
  $(`#image-${id}`).css("border-color", `${value}`);

export const getImageBorderWidth = (id: string): number =>
  Number($(`#image-${id}`).css("border-width").split("px")[0]) * 2;
export const setImageBorderWidth = (id: string, value: number) => {
  const container = $(`#image-container-${id}`);
  const image = $(`#image-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(image.css("border-width").split("px")[0]) * 2;

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  image.css("border-width", `${target / 2}`);
};
export const setImageBorderWidthByStep = (id: string, value: number) => {
  const container = $(`#image-container-${id}`);
  const image = $(`#image-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(image.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  image.css("border-width", `${target / 2}`);
};
export const setImageBorderWidthByTime = (
  id: string,
  time: number,
  value: number
) => {
  const image = $(`#image-${id}`);
  const currentBorderWidth =
    Number(image.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  return Promise.all([
    anime({
      targets: `#image-container-${id}`,
      width: `+=${delta}`,
      height: `+=${delta}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#image-${id}`,
      borderWidth: value / 2,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};

export const getImageBorderRadius = (id: string): number =>
  Number($(`#image-${id}`).data("borderRadius"));
export const setImageBorderRadius = (id: string, value: number) => {
  const image = $(`#image-${id}`);
  const currentShapeWidth = image.width();
  const currentShapeHeight = image.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  image.data("borderRadius", target);
  image.css("border-radius", size * (target / 200));
};

export const setImageBorderRadiusByStep = (id: string, value: number) => {
  const newBorderRadius =
    Number($(`#image-${id}`).data("borderRadius")) + value;
  setImageBorderRadius(id, newBorderRadius);
};
export const setImageBorderRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const image = $(`#image-${id}`);
  const currentShapeWidth = image.width();
  const currentShapeHeight = image.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  image.data("borderRadius", target);
  return Promise.all([
    anime({
      targets: `#image-${id}`,
      borderRadius: size * (target / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
