import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;

  return (
    <RuntimeContainer {...props}>
      <rect
        id={`shape-shape-${id}`}
        x={0}
        y={0}
        rx={height / 2}
        ry={height / 2}
        paintOrder="stroke"
        width={width}
        height={height}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        fill={property.style.view.backgroundColor}
      />
    </RuntimeContainer>
  );
};

export default WrapComponent;
