import $ from "jquery";
import anime from "animejs";
import { OPACITY_MIN, OPACITY_MAX } from "common/components";

export const getOpacity = (id: string): number =>
  Number($(`#${id}`).css("opacity")) * 100;
export const setOpacity = (id: string, value: number) => {
  const obj = $(`#${id}`);
  if (value > OPACITY_MAX) {
    obj.css("opacity", "1");
  } else if (value < OPACITY_MIN) {
    obj.css("opacity", "0");
  } else {
    obj.css("opacity", `${value / 100}`);
  }
};

export const setOpacityByStep = (id: string, value: number) => {
  const obj = $(`#${id}`);
  const opacity = Number(obj.css("opacity")) * 100;
  if (value + opacity > OPACITY_MAX) {
    obj.css("opacity", "1");
  } else if (value + opacity < OPACITY_MIN) {
    obj.css("opacity", "0");
  } else {
    obj.css("opacity", `${(value + opacity) / 100}`);
  }
};
export const setOpacityByTime = (id: string, time: number, value: number) => {
  let target: number;
  if (value > OPACITY_MAX) {
    target = OPACITY_MAX;
  } else if (value < OPACITY_MIN) {
    target = OPACITY_MIN;
  } else {
    target = value;
  }
  return anime({
    targets: `#${id}`,
    opacity: target / 100,
    duration: time * 1000,
    easing: "easeInOutQuad",
  }).finished;
};
