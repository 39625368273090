import { creatStarPoint } from "../../utils";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property } = props;

  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const numPoints = property.style.star.numPoints;
  const innerRadius = property.style.star.innerRadius;
  const outerRadius = property.style.star.outerRadius;

  const pointsStr = creatStarPoint(
    width,
    height,
    numPoints,
    innerRadius,
    outerRadius
  );

  return (
    <RuntimeContainer {...props}>
      <polygon
        id={`shape-shape-${id}`}
        data-num-points={numPoints}
        data-inner-radius={innerRadius}
        data-outer-radius={outerRadius}
        paintOrder="stroke"
        points={pointsStr}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={property.style.view.backgroundColor}
      />
    </RuntimeContainer>
  );
};

export default WrapComponent;
