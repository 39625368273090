import { useRef, useEffect } from "react";
import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { id, property } = props;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );

  useEffect(() => {
    ref.current.innerHTML = property.drawing.svg;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property.drawing, ref.current]);

  return (
    <div
      className="flex-col-center w-full h-full"
      id={id}
      ref={ref}
      style={{
        borderStyle: property.style.view.borderStyle,
        borderColor: property.style.view.borderColor,
        borderWidth: property.style.view.borderWidth / 2,
        borderRadius: size * (property.style.view.borderRadius / 200),
      }}
    />
  );
};

export default WrapComponent;
