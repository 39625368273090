import { EntityState } from "@reduxjs/toolkit";
import { ComponentProperty } from "common/components";

export enum ScreenOrientation {
  LANDSCAPE = "LANDSCAPE",
  PORTRAIT = "PORTRAIT",
}

export type ScreenComponent = {
  id: string;
  typeId: string;
  categoryId: string;
  name: string;
};
export type ScreensEntity = {
  id: string;
  name: string;
  order?: number;
  children: ScreenComponent[]; // component id
  childrenOrder: string[]; // component id
};

export type PropertiesEntity = {
  id: string;
  typeId: string;
  categoryId: string;
  screenId: string;
  property: ComponentProperty;
};

export type BlocklyEntity = {
  screenId: string;
  xmlText?: string;
};

export type EventsEntity = {
  id: string;
  eventId: string; // component_id_event
  componentId: string;
  code: string;
};

export interface ScreenSize {
  screenSize: {
    width: number;
    height: number;
    orientation: ScreenOrientation;
  };
}
export interface PreviewInitialStateType extends ScreenSize {
  defaultScreenId?: string;
  screens: EntityState<ScreensEntity>;
  properties: EntityState<PropertiesEntity>;
  blockly: EntityState<BlocklyEntity>;
  events: EntityState<EventsEntity>;
}
