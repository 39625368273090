import produce from "immer";
import DragLayerComponent from "./drag";
import DesignComponent from "./design";
import RuntimeComponent from "./runtime";
import {
  ComponentType,
  TextStyleProps,
  BorderStypeType,
  ShadowStyleType,
  DefaultComponentProperty,
} from "common/components/types";

const Label: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  RuntimeComponent: RuntimeComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_LABEL";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_LABEL";
    draft.text = "MSG_CREATOR_COMPONENT_NAME_LABEL";
    draft.defaultText = "MSG_CREATOR_COMPONENT_NAME_LABEL";

    const initalWidth = 50;
    const initalHeight = 20;

    draft.style.defaultText = TextStyleProps;
    draft.style.defaultLayout.width = initalWidth;
    draft.style.defaultLayout.height = initalHeight;
    draft.style.defaultView.borderStyle = BorderStypeType.NONE;
    draft.style.defaultShadow.shadowStyle = ShadowStyleType.NONE;

    draft.style.text = TextStyleProps;
    draft.style.layout.width = initalWidth;
    draft.style.layout.height = initalHeight;
    draft.style.view.borderStyle = BorderStypeType.NONE;
    draft.style.shadow.shadowStyle = ShadowStyleType.NONE;

    draft.events = ["label_click_event"];
  }),
};

export default Label;
