import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getSwitchShadowColor = (id: string) =>
  getShadowColor(`switch-shape-container-${id}`);
export const setSwitchShadowColor = (id: string, value: string) =>
  setShadowColor(`switch-shape-container-${id}`, value);

export const getSwitchShadowRadius = (id: string) =>
  getShadowRadius(`switch-shape-container-${id}`);
export const setSwitchShadowRadius = (id: string, value: number) =>
  setShadowRadius(`switch-shape-container-${id}`, value);
export const setSwitchShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`switch-shape-container-${id}`, value);
export const setSwitchShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`switch-shape-container-${id}`, time, value);
