import produce from "immer";
import DragLayerComponent from "./drag";
import DesignComponent from "./design";
import RuntimeComponent from "./runtime";
import {
  ComponentType,
  TextStyleProps,
  DefaultComponentProperty,
} from "common/components/types";

const Button: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  RuntimeComponent: RuntimeComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_BUTTON";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_BUTTON";
    draft.text = "MSG_CREATOR_COMPONENT_NAME_BUTTON";
    draft.defaultText = "MSG_CREATOR_COMPONENT_NAME_BUTTON";

    const initalBorderRadius = 100;
    const initalWidth = 90;
    const initalHeight = 40;

    draft.style.defaultLayout.width = initalWidth;
    draft.style.defaultLayout.height = initalHeight;
    draft.style.defaultText = {
      ...TextStyleProps,
      color: "rgba(253,252,253,1)",
    };
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultView.borderWidth = 4;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderRadius = initalBorderRadius;

    draft.style.layout.width = initalWidth;
    draft.style.layout.height = initalHeight;
    draft.style.text = { ...TextStyleProps, color: "rgba(253,252,253,1)" };
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.view.borderWidth = 4;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderRadius = initalBorderRadius;

    draft.events = ["button_click_event"];
  }),
};

export default Button;
