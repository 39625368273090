export enum OverflowType {
  HIDDEN = "hidden",
  VISIBLE = "visible",
  SCROLL = "scroll",
}

export type FlexAlignType =
  | "flex-start"
  | "flex-end"
  | "center"
  | "stretch"
  | "baseline";

export interface FlexStyle {
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "stretch"
    | "space-between"
    | "space-around"
    | undefined;
  alignItems?: FlexAlignType | undefined;
  alignSelf?: "auto" | FlexAlignType | undefined;
  aspectRatio?: number | undefined;
  bottom?: number | undefined;
  display?: "none" | "flex" | undefined;
  end?: number | undefined;
  flex?: number | undefined;
  flexBasis?: number | undefined;
  flexDirection?:
    | "row"
    | "column"
    | "row-reverse"
    | "column-reverse"
    | undefined;
  flexGrow?: number | undefined;
  flexShrink?: number | undefined;
  flexWrap?: "wrap" | "nowrap" | "wrap-reverse" | undefined;
  height: number;
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | undefined;
  left: number;
  margin?: number | undefined;
  marginBottom?: number | undefined;
  marginEnd?: number | undefined;
  marginHorizontal?: number | undefined;
  marginLeft?: number | undefined;
  marginRight?: number | undefined;
  marginStart?: number | undefined;
  marginTop?: number | undefined;
  marginVertical?: number | undefined;
  maxHeight?: number | undefined;
  maxWidth?: number | undefined;
  minHeight?: number | undefined;
  minWidth?: number | undefined;
  overflow?: OverflowType | undefined;
  padding?: number | undefined;
  paddingBottom?: number | undefined;
  paddingEnd?: number | undefined;
  paddingHorizontal?: number | undefined;
  paddingLeft?: number | undefined;
  paddingRight?: number | undefined;
  paddingStart?: number | undefined;
  paddingTop?: number | undefined;
  paddingVertical?: number | undefined;
  position?: "absolute" | "relative" | undefined;
  right?: number | undefined;
  start?: number | undefined;
  top: number;
  width: number;
  zIndex: number;
}

export enum BorderStypeType {
  SOLID = "solid",
  DOTTED = "dotted",
  DASHED = "dashed",
  NONE = "none",
}

export interface ViewStyle {
  backfaceVisibility?: "visible" | "hidden" | undefined;
  backgroundColor?: string | undefined;
  borderBottomColor?: string | undefined;
  borderBottomEndRadius?: number | undefined;
  borderBottomLeftRadius?: number | undefined;
  borderBottomRightRadius?: number | undefined;
  borderBottomStartRadius?: number | undefined;
  borderBottomWidth?: number | undefined;
  borderColor?: string | undefined;
  borderEndColor?: string | undefined;
  borderLeftColor?: string | undefined;
  borderLeftWidth?: number | undefined;
  borderRadius?: number | undefined;
  borderRightColor?: string | undefined;
  borderRightWidth?: number | undefined;
  borderStartColor?: string | undefined;
  borderStyle?: BorderStypeType | undefined;
  borderTopColor?: string | undefined;
  borderTopEndRadius?: number | undefined;
  borderTopLeftRadius?: number | undefined;
  borderTopRightRadius?: number | undefined;
  borderTopStartRadius?: number | undefined;
  borderTopWidth?: number | undefined;
  borderWidth?: number | undefined;
  opacity?: number | undefined;
  testID?: string | undefined;
}

export enum FontStyleType {
  NORMAL = "normal",
  ITALIC = "italic",
}

export enum FontWeightType {
  NORMAL = "normal",
  BOLD = "bold",
  ONE = "100",
  TWO = "200",
  THREE = "300",
  FOUR = "400",
  FIVE = "500",
  SIX = "600",
  SEVEN = "700",
  EIGHT = "800",
  NINE = "900",
}

export enum TextAlignType {
  AUTO = "auto",
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
  JUSTIFY = "justify",
}
export enum TextDecorationLineType {
  NONE = "none",
  UNDERLINE = "underline",
  LINE_THROUGH = "line-through",
  UNDERLINE_LINE_THROUGH = "underline line-through",
}
export enum TextTransform {
  NONE = "none",
  UPPERCASE = "uppercase",
  LOWERCASE = "lowercase",
  CAPITALIZE = "capitalize",
}

export interface TextStyle {
  color?: string | undefined;
  opacity?: number | undefined;
  fontFamily?: string | undefined;
  fontSize?: number | undefined;
  fontStyle?: FontStyleType | undefined;
  fontWeight?: FontWeightType | undefined;
  letterSpacing?: number | undefined;
  lineHeight?: number | undefined;
  textAlign?: TextAlignType | undefined;
  textDecorationLine?: TextDecorationLineType | undefined;
  textDecorationStyle?: TextDecorationLineType | undefined;
  textDecorationColor?: string | undefined;
  textShadowWeight?: number | undefined;
  textShadowColor?: string | undefined;
  textShadowOffset?: { width: number; height: number } | undefined;
  textShadowRadius?: number | undefined;
  textTransform?: TextTransform | undefined;
}

export interface StarStyle {
  innerRadius: number;
  outerRadius: number;
  numPoints: number;
}

export interface RegularPolygonStyle {
  sides: number;
  radius: number;
}

export interface TransformStyle {
  translateX: number;
  translateY: number;
  scaleX: number;
  scaleY: number;
  radius: number;
  rotation: string;
  transformOrigin: string;
}

export enum ImageResizeMode {
  COVER = "cover",
  CONTAIN = "contain",
  STRETCH = "stretch",
  REPEAT = "repeat",
  CENTER = "center",
}

export enum ImageType {
  PHOTO = "photo",
  DRAW = "draw",
  STAMP = "stamp",
}

export interface ImageStyle {
  name: string;
  size: number;
  width: number;
  height: number;
  uri: string;
  type: ImageType;
  resizeMode: ImageResizeMode;
}

export enum ShadowStyleType {
  DROP = "drop",
  NONE = "none",
}

export interface ShadowStyle {
  shadowWeight?: number | undefined;
  shadowStyle: ShadowStyleType | undefined;
  shadowColor?: string | undefined;
  shadowOffset?: { width: number; height: number } | undefined;
  shadowOpacity?: number | undefined;
  shadowRadius?: number | undefined;
}

export interface ResizeConfig {
  lockAspectRatioX: boolean;
  lockAspectRatioY: boolean;
}
