import { Provider } from "react-redux";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { store } from "app/store";
import { AuthRoute } from "app/Auth";
import { PreviewIndexPage } from "features/preview";
import { NotFoundPage } from "features/notFound";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      xml: unknown;
      sep: unknown;
      category: unknown;
      block: unknown;
      value: unknown;
      field: unknown;
      shadow: unknown;
      variables: unknown;
      variable: unknown;
      mutation: unknown;
    }
  }
}

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthRoute>
          <Routes>
            <Route path="/" element={<PreviewIndexPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </AuthRoute>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
