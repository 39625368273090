import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;
  const width = property.style.layout.width * scale;
  const height = property.style.layout.height * scale;
  const borderWidth = property.style.view.borderWidth * scale;

  return (
    <svg
      overflow="visible"
      preserveAspectRatio="none"
      width={width + borderWidth}
      height={height + borderWidth}
      viewBox={`${-borderWidth / 2} ${-borderWidth / 2}  ${
        width + borderWidth
      } ${height + borderWidth}`}
    >
      <polygon
        paintOrder="stroke"
        points={`${width / 2},${0} ${0},${height} ${width},${height}`}
        stroke={props.property.style.view.borderColor}
        strokeWidth={props.property.style.view.borderWidth}
        strokeLinejoin="miter"
        strokeMiterlimit={100}
        fill={props.property.style.view.backgroundColor}
      />
    </svg>
  );
};

export default WrapComponent;
