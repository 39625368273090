import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getCheckboxShadowColor = (id: string) =>
  getShadowColor(`checkbox-shape-container-${id}`);
export const setCheckboxShadowColor = (id: string, value: string) =>
  setShadowColor(`checkbox-shape-container-${id}`, value);

export const getCheckboxShadowRadius = (id: string) =>
  getShadowRadius(`checkbox-shape-container-${id}`);
export const setCheckboxShadowRadius = (id: string, value: number) =>
  setShadowRadius(`checkbox-shape-container-${id}`, value);
export const setCheckboxShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`checkbox-shape-container-${id}`, value);
export const setCheckboxShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`checkbox-shape-container-${id}`, time, value);
