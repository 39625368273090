import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import {
  BlocklyJsOperator,
  initPropertyGetterBlock,
  initPropertySetterBlock,
  initPropertySetterByTimeBlock,
} from "common/blockly";
import { BORDER_SIZE_MIN, BORDER_SIZE_MAX } from "common/components";

export const InitBorderPropertyBlock = (
  components: string[][],
  defaultComponent?: string | undefined
) => {
  function showEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      components.length === 0 ? [["パーツ", ""]] : components,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  Blockly.Blocks["component_property_getter_border_color"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_border_color",
          "%{BKY_GETTER_BORDER_COLOR}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "colour_blocks",
          "Colour"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_border_color"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.get${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }BorderColor("${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["component_property_setter_border_color"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_border_color",
          "%{BKY_SETTER_BORDER_COLOR}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "colour_blocks",
          "Colour"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_border_color"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }BorderColor("${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_border_width"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_border_width",
          "%{BKY_GETTER_BORDER_WIDTH}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_border_width"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.get${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }BorderWidth("${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };

  Blockly.Blocks["component_property_setter_border_width"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_border_width",
          "%{BKY_SETTER_BORDER_WIDTH}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > BORDER_SIZE_MAX) {
              return BORDER_SIZE_MAX;
            } else if (value < BORDER_SIZE_MIN) {
              return BORDER_SIZE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_border_width"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }BorderWidth("${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_border_width_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_border_width_by_step",
          "%{BKY_SETTER_BORDER_WIDTH_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > BORDER_SIZE_MAX) {
              return BORDER_SIZE_MAX;
            } else if (value < -BORDER_SIZE_MAX) {
              return -BORDER_SIZE_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_border_width_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }BorderWidthByStep("${component[1]}", ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_border_width_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_border_width_by_time",
          "%{BKY_SETTER_BORDER_WIDTH_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > BORDER_SIZE_MAX) {
              return BORDER_SIZE_MAX;
            } else if (value < BORDER_SIZE_MIN) {
              return BORDER_SIZE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_border_width_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }BorderWidthByTime("${component[1]}", ${time}, ${value});\n`;
    return code;
  };
};
