import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getLabelShadowColor = (id: string) =>
  getShadowColor(`label-shape-container-${id}`);
export const setLabelShadowColor = (id: string, value: string) =>
  setShadowColor(`label-shape-container-${id}`, value);

export const getLabelShadowRadius = (id: string) =>
  getShadowRadius(`label-shape-container-${id}`);
export const setLabelShadowRadius = (id: string, value: number) =>
  setShadowRadius(`label-shape-container-${id}`, value);
export const setLabelShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`label-shape-container-${id}`, value);
export const setLabelShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`label-shape-container-${id}`, time, value);
