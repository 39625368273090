import i18n from "i18n-js";
import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;

  return (
    <div
      className="flex-col-center origin-top-left"
      style={{
        width: property.style.layout.width,
        height: property.style.layout.height,
        transform: `translateZ(0) scale(${scale})`,
        backfaceVisibility: "hidden",
        WebkitBackfaceVisibility: "hidden",
      }}
    >
      <div className="flex-row-center">
        <input
          type="checkbox"
          className="m-0"
          style={{
            width: property.style.text.fontSize,
            height: property.style.text.fontSize,
          }}
        />
        <label
          className="align-middle"
          style={{
            color: property.style.text.color,
            fontFamily: property.style.text.fontFamily,
            fontSize: property.style.text.fontSize,
            letterSpacing: property.style.text.letterSpacing,
          }}
        >
          {i18n.t(property.text)}
        </label>
      </div>
    </div>
  );
};

export default WrapComponent;
