/* eslint-disable no-new-func */
import { useState, useEffect, useContext } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "app/Auth";
import { RootState, AppDispatch } from "app/store";
import { useScale } from "common/utils";
import { ComponentManager, ComponentTypeIds } from "common/components";
import {
  selectSceenById,
  selectAllEvents,
  selectPropertyById,
} from "features/preview/slice";
import { RuntimeView } from "features/preview/RuntimeView";
import { getUserProjectContent } from "features/preview/api";
import { useGenerateEvent } from "./useGenerateEvent";

const Preview = () => {
  useGenerateEvent();
  useEffect(() => {
    const root = document.body;

    const newNode = document.createElement("p");
    newNode.id = "compute-text-length";
    newNode.style.opacity = "0";

    const newDivNode = document.createElement("div");
    newDivNode.style.position = "absolute";
    newDivNode.style.top = "0";
    newDivNode.style.left = "0";
    newDivNode.style.zIndex = "-10000";
    newDivNode.appendChild(newNode);

    root.appendChild(newDivNode);

    return () => {
      root.removeChild(newDivNode);
    };
  }, []);

  const { width, height, ratio } = useScale();
  const screenSize = useSelector(
    (state: RootState) => state.preview.screenSize
  );

  const screenRatio = screenSize.width / screenSize.height;
  const scale =
    ratio > screenRatio ? height / screenSize.height : width / screenSize.width;

  const defaultScreenId = useSelector(
    (state: RootState) => state.preview.defaultScreenId
  );
  const [currentScreenId, setCurrentScreenId] = useState(defaultScreenId);
  const currentScreen = useSelector((state: RootState) =>
    selectSceenById(state, currentScreenId)
  );
  const screenPropertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, currentScreenId)
  );
  const Screen =
    ComponentManager[ComponentTypeIds.SCREEN].component.RuntimeComponent;

  const navigate = (id: string) => setCurrentScreenId(id);

  const events = useSelector(selectAllEvents);

  const variablesCode = events
    .filter((event) => event.eventId === `${currentScreenId}_variables`)
    .shift();

  const proceduresCode = events
    .filter((event) => event.eventId === `${currentScreenId}_procedures`)
    .shift();

  return (
    <div className="flex-col-center w-full h-full bg-white">
      <div
        className="flex-col-center"
        style={{
          width: screenSize.width * scale,
          height: screenSize.height * scale,
        }}
      >
        <div
          className="flex-col-center bg-white"
          style={{
            width: screenSize.width,
            height: screenSize.height,
            transform: `scale(${scale})`,
            backfaceVisibility: "hidden",
            WebkitBackfaceVisibility: "hidden",
          }}
        >
          <Screen
            key={currentScreenId}
            id={currentScreenId}
            property={screenPropertyEntity.property}
            events={events.filter(
              (event) => event.componentId === currentScreenId
            )}
            initVariables={variablesCode?.code}
            proceduresCode={proceduresCode?.code}
          >
            {currentScreen.children.map((component, index) => {
              return (
                <RuntimeView
                  key={index}
                  id={component.id}
                  navigate={navigate}
                  events={events.filter(
                    (event) => event.componentId === component.id
                  )}
                  initVariables={variablesCode?.code}
                  proceduresCode={proceduresCode?.code}
                  zIndex={currentScreen.childrenOrder.indexOf(component.id)}
                />
              );
            })}
          </Screen>
        </div>
      </div>
    </div>
  );
};

export const NotFoundPage = () => {
  return (
    <div className="flex-col-center w-full h-full bg-white">
      <p className="text  text-textcolor/black">プロジェクトが存在しません。</p>
    </div>
  );
};

export const LoadingPage = () => {
  return (
    <div className="flex-col-center w-full h-full bg-white">
      <p className="text  text-textcolor/black">読み込み中。。。</p>
    </div>
  );
};

export const PreviewIndexPage = () => {
  const auth = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const project_id = searchParams.get("project_id");
    const source = searchParams.get("source");
    const uid = searchParams.get("uid");
    if (project_id !== "undefined" && project_id) {
      dispatch(
        getUserProjectContent({
          authUser: auth.user,
          source: source ?? "web",
          uid: uid ?? auth.user.uid,
          project_id: project_id,
        })
      )
        .then(unwrapResult)
        .then((project) => {
          setLoading(false);
        })
        .catch((error) => {
          setNotFound(true);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return loading ? <LoadingPage /> : notFound ? <NotFoundPage /> : <Preview />;
};
