import produce from "immer";
import DesignComponent from "./design";
import RuntimeComponent from "./runtime";
import {
  ComponentType,
  BorderStypeType,
  ShadowStyleType,
  DefaultComponentProperty,
} from "common/components/types";

const Screen: ComponentType = {
  DesignComponent: DesignComponent,
  RuntimeComponent: RuntimeComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_SCREEN";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_SCREEN";

    const initalWidth = 1024;
    const initalHeight = 768;

    draft.style.defaultLayout.width = initalWidth;
    draft.style.defaultLayout.height = initalHeight;
    draft.style.defaultView.backgroundColor = "#ffffff";
    draft.style.defaultView.borderStyle = BorderStypeType.NONE;
    draft.style.defaultShadow.shadowStyle = ShadowStyleType.NONE;

    draft.style.layout.width = initalWidth;
    draft.style.layout.height = initalHeight;
    draft.style.layout.position = "relative";
    draft.style.view.backgroundColor = "#ffffff";
    draft.style.view.borderStyle = BorderStypeType.NONE;
    draft.style.shadow.shadowStyle = ShadowStyleType.NONE;

    draft.events = [
      "screen_loading_event",
      "screen_click_event",
      "screen_navigate_event",
      "variables_procedures",
    ];
  }),
};

export default Screen;
