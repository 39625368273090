import $ from "jquery";
import anime from "animejs";
import {
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BORDER_RADIUS_MIN,
  BORDER_RADIUS_MAX,
} from "common/components";

export const getShapeBorderColor = (id: string) =>
  $(`#shape-shape-${id}`).attr("stroke");
export const setShapeBorderColor = (id: string, value: string) =>
  $(`#shape-shape-${id}`).attr("stroke", `${value}`);

export const getShapeBorderWidth = (id: string): number =>
  Number($(`#shape-shape-${id}`).attr("stroke-width").split("px")[0]);
export const setShapeBorderWidth = (id: string, value: number) => {
  const svg = $(`#shape-svg-${id}`);
  const shape = $(`#shape-shape-${id}`);
  const currentWidth = svg.width();
  const currentHeight = svg.height();
  const viewBox = svg.attr("viewBox").split(" ");
  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }

  const delta = target - currentBorderWidth;
  svg.attr("width", currentWidth + delta);
  svg.attr("height", currentHeight + delta);
  svg.attr(
    "viewBox",
    `${Number(viewBox[0]) - delta / 2} ${Number(viewBox[1]) - delta / 2} ${
      Number(viewBox[2]) + delta
    } ${Number(viewBox[3]) + delta}`
  );
  shape.attr("stroke-width", `${currentBorderWidth + delta}`);
};
export const setShapeBorderWidthByStep = (id: string, value: number) => {
  const svg = $(`#shape-svg-${id}`);
  const shape = $(`#shape-shape-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentWidth = svg.width();
  const currentHeight = svg.height();
  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);

  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  svg.attr("width", currentWidth + delta);
  svg.attr("height", currentHeight + delta);
  svg.attr(
    "viewBox",
    `${Number(viewBox[0]) - delta / 2} ${Number(viewBox[1]) - delta / 2} ${
      Number(viewBox[2]) + delta
    } ${Number(viewBox[3]) + delta}`
  );
  shape.attr("stroke-width", `${currentBorderWidth + delta}`);
};
export const setShapeBorderWidthByTime = (
  id: string,
  time: number,
  value: number
) => {
  const svg = $(`#shape-svg-${id}`);
  const shape = $(`#shape-shape-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentBorderWidth = Number(shape.css("stroke-width").split("px")[0]);

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }

  const delta = target - currentBorderWidth;
  return Promise.all([
    anime({
      targets: `#shape-svg-${id}`,
      width: `+=${delta}`,
      height: `+=${delta}`,
      viewBox: `${Number(viewBox[0]) - delta / 2} ${
        Number(viewBox[1]) - delta / 2
      } ${Number(viewBox[2]) + delta} ${Number(viewBox[3]) + delta}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#shape-shape-${id}`,
      strokeWidth: value,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};

// 今border-radiusがRECTANGLEに適応しかない
export const getShapeBorderRadius = (id: string): number =>
  Number($(`#shape-shape-${id}`).data("borderRadius"));
export const setShapeBorderRadius = (id: string, value: number) => {
  const shape = $(`#shape-shape-${id}`);
  const currentShapeWidth = shape.width();
  const currentShapeHeight = shape.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }
  shape.data("borderRadius", target);
  shape.attr("rx", size * (target / 200));
};
export const setShapeBorderRadiusByStep = (id: string, value: number) => {
  const newBorderRadius =
    Number($(`#shape-shape-${id}`).data("borderRadius")) + value;
  setShapeBorderRadius(id, newBorderRadius);
};
export const setShapeBorderRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const shape = $(`#shape-shape-${id}`);
  const currentShapeWidth = shape.width();
  const currentShapeHeight = shape.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }
  shape.data("borderRadius", target);
  return Promise.all([
    anime({
      targets: `#shape-shape-${id}`,
      rx: size * (target / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
