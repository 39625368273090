import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );
  return (
    <svg
      id={id}
      overflow="visible"
      preserveAspectRatio="none"
      viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${
        property.style.layout.width + SizeOffset
      } ${property.style.layout.height + SizeOffset}`}
    >
      <rect
        x={0}
        y={0}
        rx={size * (property.style.view.borderRadius / 200)}
        paintOrder="stroke"
        width={property.style.layout.width}
        height={property.style.layout.height}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        fill={property.style.view.backgroundColor}
      />
    </svg>
  );
};

export default WrapComponent;
