import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "common/apiClient";

export const getKidsPreviewRuntimeLatestVersion = createAsyncThunk(
  "app/getKidsPreviewRuntimeLatestVersion",
  async ({ key }: { key: string }) => {
    const response = await client({ token: key }).get(
      "/v1/release/kids/preview/runtime/latest"
    );
    return response.data.data.releases;
  }
);
