/* eslint-disable no-new-func */
import chroma from "chroma-js";
import * as codeGen from "common/blockly/codeGen";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property, navigate, events, proceduresCode } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );

  const onClickEvents = events
    .filter((event) => event.eventId.includes(`${id}_button_click_event`))
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnClick = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();
  const textShadowColor = chroma(property.style.text.textShadowColor).rgba();

  return (
    <RuntimeContainer
      {...props}
      onClick={handleOnClick(onClickEvents, navigate, codeGen)}
    >
      <div
        id={`button-shape-container-${id}`}
        className="w-full h-full"
        style={{
          filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
            property.style.shadow.shadowOffset.height
          }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
            ${shadowColor[1]},
            ${shadowColor[2]},
            ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
          ))`,
        }}
      >
        <svg
          id={`button-svg-${id}`}
          overflow="visible"
          preserveAspectRatio="none"
          viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${
            width + SizeOffset
          } ${height + SizeOffset}`}
        >
          <rect
            id={`button-shape-${id}`}
            data-border-radius={property.style.view.borderRadius}
            x={0}
            y={0}
            rx={size * (property.style.view.borderRadius / 200)}
            paintOrder="stroke"
            width={width}
            height={height}
            stroke={property.style.view.borderColor}
            strokeWidth={property.style.view.borderWidth}
            fill={property.style.view.backgroundColor}
          />
        </svg>
      </div>
      <div className="flex-row-center !absolute top-0 left-0 w-full h-full pointer-events-auto">
        <p
          id={`button-text-${id}`}
          style={{
            userSelect: "none",
            fontFamily: property.style.text.fontFamily,
            letterSpacing: property.style.text.letterSpacing,
            fontSize: property.style.text.fontSize,
            color: property.style.text.color,
            margin: 0,
            textAlign: "center",
            verticalAlign: "middle",
            whiteSpace: "pre-line",
            opacity: property.style.text.opacity / 100,
            textShadow: `${property.style.text.textShadowOffset.width}px ${
              property.style.text.textShadowOffset.height
            }px ${property.style.text.textShadowRadius}px rgba(${
              textShadowColor[0]
            },
              ${textShadowColor[1]},
              ${textShadowColor[2]},
              ${
                property.style.text.textShadowRadius === 0
                  ? 0
                  : textShadowColor[3]
              }
            )`,
          }}
        >
          {property.text}
        </p>
      </div>
    </RuntimeContainer>
  );
};

export default WrapComponent;
