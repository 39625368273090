import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );
  return (
    <div
      style={{
        width: property.style.layout.width,
        height: property.style.layout.height,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <img
        alt=""
        src={property.style.image.uri}
        style={{
          width: "100%",
          height: "100%",
          borderStyle: property.style.view.borderStyle,
          borderColor: property.style.view.borderColor,
          borderWidth: property.style.view.borderWidth / 2,
          borderRadius: size * (property.style.view.borderRadius / 200),
        }}
      />
    </div>
  );
};

export default WrapComponent;
