import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;

  return (
    <div id={id} className="flex-row-center w-full h-full">
      <input
        type="checkbox"
        className="m-0"
        style={{
          backfaceVisibility: "hidden",
          width: property.style.text.fontSize,
          height: property.style.text.fontSize,
        }}
      />
      <label
        className="align-middle"
        style={{
          color: property.style.text.color,
          fontFamily: property.style.text.fontFamily,
          fontSize: property.style.text.fontSize,
          letterSpacing: property.style.text.letterSpacing,
          textShadow:
            property.style.text.textShadowRadius !== 0 &&
            `${property.style.text.textShadowOffset.width}px ${property.style.text.textShadowOffset.height}px ${property.style.text.textShadowRadius}px ${property.style.text.textShadowColor}`,
        }}
      >
        {property.text}
      </label>
    </div>
  );
};

export default WrapComponent;
