/* eslint-disable no-new-func */
import chroma from "chroma-js";
import * as codeGen from "common/blockly/codeGen";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property, navigate, events, proceduresCode } = props;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );

  const onClickEvents = events
    .filter((event) => event.eventId.includes(`${id}_stamp_click_event`))
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnClick = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    ${proceduresCode};
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();
  return (
    <RuntimeContainer {...props}>
      <div
        id={`stamp-shape-container-${id}`}
        className="flex-col-center w-full h-full"
        style={{
          filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
            property.style.shadow.shadowOffset.height
          }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
            ${shadowColor[1]},
            ${shadowColor[2]},
            ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
          ))`,
        }}
      >
        <img
          alt=""
          id={`stamp-${id}`}
          data-border-radius={property.style.view.borderRadius}
          src={property.style.image.uri}
          style={{
            width: property.style.layout.width,
            height: property.style.layout.height,
            borderStyle: property.style.view.borderStyle,
            borderColor: property.style.view.borderColor,
            borderWidth: property.style.view.borderWidth / 2,
            borderRadius: size * (property.style.view.borderRadius / 200),
          }}
          onPointerDown={handleOnClick(onClickEvents, navigate, codeGen)}
        />
      </div>
    </RuntimeContainer>
  );
};

export default WrapComponent;
