import $ from "jquery";

export * from "./shadow";
export * from "./size";
export * from "./text";

export const getCheckboxValue = (id: string): boolean =>
  Boolean($(`#checkbox-${id}`).prop("checked"));

export const setCheckboxValue = (id: string, value: boolean) =>
  $(`#checkbox-${id}`).prop("checked", value);
