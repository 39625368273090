import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;

  return (
    <div
      id={id}
      className="absolute top-0 left-0"
      style={{
        width: property.style.layout.width,
        height: property.style.layout.height,
        backgroundColor: property.style.view.backgroundColor,
      }}
    >
      {props.children}
    </div>
  );
};

export default WrapComponent;
