import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getTextinputShadowColor = (id: string) =>
  getShadowColor(`textinput-text-${id}`);
export const setTextinputShadowColor = (id: string, value: string) =>
  setShadowColor(`textinput-text-${id}`, value);

export const getTextinputShadowRadius = (id: string) =>
  getShadowRadius(`textinput-text-${id}`);
export const setTextinputShadowRadius = (id: string, value: number) =>
  setShadowRadius(`textinput-text-${id}`, value);
export const setTextinputShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`textinput-text-${id}`, value);
export const setTextinputShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`textinput-text-${id}`, time, value);
