import {
  WIDTH_MIN,
  WIDTH_MAX,
  HEIGHT_MIN,
  HEIGHT_MAX,
} from "common/components";
import { calcOffset, getTransformPropertyValue } from "common/blockly/codeGen";

export const setSize = (
  container: JQuery<HTMLElement>,
  currentContainerWidth: number,
  currentContainerHeight: number,
  currentShapeWidth: number,
  currentShapeHeight: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const [translateX, translateY, rotate, scale] = getTransformPropertyValue(
    container
  );

  let targetWidth: number;
  if (width > WIDTH_MAX) {
    targetWidth = WIDTH_MAX;
  } else if (width < WIDTH_MIN) {
    targetWidth = WIDTH_MIN;
  } else {
    targetWidth = width;
  }

  let targetHeight: number;
  if (height > HEIGHT_MAX) {
    targetHeight = HEIGHT_MAX;
  } else if (height < HEIGHT_MIN) {
    targetHeight = HEIGHT_MIN;
  } else {
    targetHeight = height;
  }

  const deltaX = width === undefined ? 0 : targetWidth - currentShapeWidth;
  const deltaY = height === undefined ? 0 : targetHeight - currentShapeHeight;

  const offset = calcOffset(
    currentShapeWidth,
    currentShapeHeight,
    (rotate * Math.PI) / 180,
    {
      x: deltaX,
      y: deltaY,
    }
  );

  container.css(
    "transform",
    `translateX(${translateX - offset.x}px) translateY(${
      translateY + offset.y
    }px) rotate(${rotate}deg) scale(${scale})`
  );

  const newShapeSize = {
    x: width === undefined ? currentShapeWidth : targetWidth,
    y: height === undefined ? currentShapeHeight : targetHeight,
  };
  const newContainerSize = {
    x: currentContainerWidth + deltaX,
    y: currentContainerHeight + deltaY,
  };
  return [newContainerSize, newShapeSize];
};

export const setSizeByTime = (
  container: JQuery<HTMLElement>,
  currentContainerWidth: number,
  currentContainerHeight: number,
  currentShapeWidth: number,
  currentShapeHeight: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const [, , rotate] = getTransformPropertyValue(container);

  let targetWidth: number;
  if (width > WIDTH_MAX) {
    targetWidth = WIDTH_MAX;
  } else if (width < WIDTH_MIN) {
    targetWidth = WIDTH_MIN;
  } else {
    targetWidth = width;
  }

  let targetHeight: number;
  if (height > HEIGHT_MAX) {
    targetHeight = HEIGHT_MAX;
  } else if (height < HEIGHT_MIN) {
    targetHeight = HEIGHT_MIN;
  } else {
    targetHeight = height;
  }

  const deltaX = width === undefined ? 0 : targetWidth - currentShapeWidth;
  const deltaY = height === undefined ? 0 : targetHeight - currentShapeHeight;

  const offset = calcOffset(
    currentShapeWidth,
    currentShapeHeight,
    (rotate * Math.PI) / 180,
    {
      x: deltaX,
      y: deltaY,
    }
  );

  const newShapeSize = {
    x: width === undefined ? currentShapeWidth : targetWidth,
    y: height === undefined ? currentShapeHeight : targetHeight,
  };
  const newContainerSize = {
    x: currentContainerWidth + deltaX,
    y: currentContainerHeight + deltaY,
  };
  return [newContainerSize, newShapeSize, offset];
};
