import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app";
import i18n from "i18n-js";
import { I18nTranslations } from "common/i18n";

i18n.locale = "ja";
i18n.fallbacks = true;
i18n.translations = I18nTranslations;

ReactDOM.render(<App />, document.getElementById("root"));
