/* eslint-disable no-new-func */
import { useRef, useEffect } from "react";
import chroma from "chroma-js";
import * as codeGen from "common/blockly/codeGen";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { id, property, navigate, events, proceduresCode } = props;
  const size = Math.min(
    property.style.layout.width,
    property.style.layout.height
  );

  const onClickEvents = events
    .filter((event) => event.eventId.includes(`${id}_drawing_click_event`))
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnClick = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  useEffect(() => {
    ref.current.innerHTML = property.drawing.svg;
    const paths = ref.current.getElementsByTagName("path");
    for (let i = 0; i < paths.length; i++) {
      const path = paths.item(i);
      path.style.pointerEvents = "auto";
      path.addEventListener(
        "click",
        handleOnClick(onClickEvents, navigate, codeGen)
      );
    }
    return () => {
      for (let i = 0; i < paths.length; i++) {
        const path = paths.item(i);
        path.removeEventListener(
          "click",
          handleOnClick(onClickEvents, navigate, codeGen)
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, events]);

  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();
  return (
    <RuntimeContainer {...props} pointerEvents="none">
      <div
        id={`drawing-shape-container-${id}`}
        className="flex-col-center w-full h-full"
        style={{
          filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
            property.style.shadow.shadowOffset.height
          }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
            ${shadowColor[1]},
            ${shadowColor[2]},
            ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
          ))`,
        }}
      >
        <div
          ref={ref}
          id={`drawing-${id}`}
          data-border-radius={property.style.view.borderRadius}
          className="flex-col-center w-full h-full"
          style={{
            borderStyle: property.style.view.borderStyle,
            borderColor: property.style.view.borderColor,
            borderWidth: property.style.view.borderWidth / 2,
            borderRadius: size * (property.style.view.borderRadius / 200),
          }}
        />
      </div>
    </RuntimeContainer>
  );
};

export default WrapComponent;
