import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import {
  BlocklyJsOperator,
  initPropertyGetterBlock,
  initPropertySetterBlock,
  initPropertySetterByTimeBlock,
} from "common/blockly";
import { Vector2D } from "common/types";
import { X_MAX, Y_MAX, SCALE_MIN, SCALE_MAX } from "common/components";

export const InitAngleBlock = () => {
  Blockly.Blocks["math_angle"] = {
    init: function () {
      this.appendDummyInput().appendField(new Blockly.FieldAngle(0), "value");
      this.setOutput(true, "Number");
      this.setStyle("math_blocks");
    },
  };

  BlocklyJs["math_angle"] = function (block: Blockly.BlockSvg) {
    const angle = Number(block.getFieldValue("value"));
    return [angle, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitPositionPropertyBlock = (
  components: string[][],
  defaultComponent?: string | undefined
) => {
  function showEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      components.length === 0 ? [["パーツ", ""]] : components,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  Blockly.Blocks["component_property_getter_x"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_x",
          "%{BKY_GETTER_X_COORDINATE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_x"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTranslateX("${component[0]}-container-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };

  Blockly.Blocks["component_property_setter_x"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_x",
          "%{BKY_SETTER_X_COORDINATE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              this.getValue(),
              undefined,
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  this.setValue(coor.x);
                }
              }.bind(this)
            );
          };
        }
      });
    },
  };
  BlocklyJs["component_property_setter_x"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTranslateXY("${component[0]}-container-${component[1]}", ${value}, undefined);\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_x_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_x_by_step",
          "%{BKY_SETTER_X_COORDINATE_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > X_MAX) {
              return X_MAX;
            } else if (value < -X_MAX) {
              return -X_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_x_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTranslateXYByStep("${component[0]}-container-${component[1]}", ${value}, undefined);\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_x_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_x_by_time",
          "%{BKY_SETTER_X_COORDINATE_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              this.getValue(),
              undefined,
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  this.setValue(coor.x);
                }
              }.bind(this)
            );
          };
        }
      });
    },
  };
  BlocklyJs["component_property_setter_x_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setTranslateXYByTime("${component[0]}-container-${component[1]}",${time}, ${value}, undefined);\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_x_by_step_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_x_by_step_time",
          "%{BKY_SETTER_X_COORDINATE_BY_STEP_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > X_MAX) {
              return X_MAX;
            } else if (value < -X_MAX) {
              return -X_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_x_by_step_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setTranslateXYByStepAndTime("${component[0]}-container-${component[1]}",${time}, ${value}, undefined);\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_y"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_y",
          "%{BKY_GETTER_Y_COORDINATE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_y"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getTranslateY("${component[0]}-container-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };

  Blockly.Blocks["component_property_setter_y"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_y",
          "%{BKY_SETTER_Y_COORDINATE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              undefined,
              this.getValue(),
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  this.setValue(coor.y);
                }
              }.bind(this)
            );
          };
        }
      });
    },
  };
  BlocklyJs["component_property_setter_y"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTranslateXY("${component[0]}-container-${component[1]}", undefined, ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_y_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_y_by_step",
          "%{BKY_SETTER_Y_COORDINATE_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > Y_MAX) {
              return Y_MAX;
            } else if (value < -Y_MAX) {
              return -Y_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_y_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTranslateXYByStep("${component[0]}-container-${component[1]}", undefined, ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_y_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_y_by_time",
          "%{BKY_SETTER_Y_COORDINATE_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              undefined,
              this.getValue(),
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  this.setValue(coor.y);
                }
              }.bind(this)
            );
          };
        }
      });
    },
  };
  BlocklyJs["component_property_setter_y_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setTranslateXYByTime("${component[0]}-container-${component[1]}",${time}, undefined, ${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_y_by_step_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_y_by_step_time",
          "%{BKY_SETTER_Y_COORDINATE_BY_STEP_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > Y_MAX) {
              return Y_MAX;
            } else if (value < -Y_MAX) {
              return -Y_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_y_by_step_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setTranslateXYByStepAndTime("${component[0]}-container-${component[1]}",${time}, undefined, ${value});\n`;
    return code;
  };

  const xyGen = (componentsField: string[][]) => ({
    type: "component_property_setter_x_y",
    message0: "%{BKY_SETTER_X_Y_COORDINATE}",
    args0: [
      {
        type: "field_dropdown",
        name: "component_id",
        options: componentsField,
      },
      {
        type: "input_value",
        name: "x",
        check: "Number",
      },
      {
        type: "input_value",
        name: "y",
        check: "Number",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    style: "logic_blocks",
  });
  Blockly.Blocks["component_property_setter_x_y"] = {
    init: function () {
      this.jsonInit(
        xyGen(components.length === 0 ? [["パーツ", ""]] : components)
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputXTarget = this.getInputTargetBlock("x") as Blockly.Block;
        const inputYTarget = this.getInputTargetBlock("y") as Blockly.Block;
        if (
          inputXTarget &&
          inputXTarget.getField("NUM") &&
          inputYTarget &&
          inputYTarget.getField("NUM")
        ) {
          inputXTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              inputXTarget.getField("NUM").getValue(),
              inputYTarget.getField("NUM").getValue(),
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  inputXTarget.getField("NUM").setValue(coor.x);
                  inputYTarget.getField("NUM").setValue(coor.y);
                }
              }
            );
          };
          inputYTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              inputXTarget.getField("NUM").getValue(),
              inputYTarget.getField("NUM").getValue(),
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  inputXTarget.getField("NUM").setValue(coor.x);
                  inputYTarget.getField("NUM").setValue(coor.y);
                }
              }
            );
          };
        }
      });
    },
  };
  BlocklyJs["component_property_setter_x_y"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const x = BlocklyJs.valueToCode(block, "x", BlocklyJsOperator.ORDER_NONE);
    const y = BlocklyJs.valueToCode(block, "y", BlocklyJsOperator.ORDER_NONE);
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setTranslateXY("${component[0]}-container-${component[1]}", ${x}, ${y});\n`;
    return code;
  };

  const xyTimeBlockGen = (componentsField: string[][]) => ({
    type: "component_property_setter_x_y_by_time",
    message0: "%{BKY_SETTER_X_Y_BY_TIME}",
    args0: [
      {
        type: "input_value",
        name: "time",
        check: "Number",
      },
      {
        type: "field_dropdown",
        name: "component_id",
        options: componentsField,
      },
      {
        type: "input_value",
        name: "x",
        check: "Number",
      },
      {
        type: "input_value",
        name: "y",
        check: "Number",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    style: "math_blocks",
  });
  Blockly.Blocks["component_property_setter_x_y_by_time"] = {
    init: function () {
      this.jsonInit(
        xyTimeBlockGen(components.length === 0 ? [["パーツ", ""]] : components)
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputXTarget = this.getInputTargetBlock("x") as Blockly.Block;
        const inputYTarget = this.getInputTargetBlock("y") as Blockly.Block;
        if (
          inputXTarget &&
          inputXTarget.getField("NUM") &&
          inputYTarget &&
          inputYTarget.getField("NUM")
        ) {
          inputXTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              inputXTarget.getField("NUM").getValue(),
              inputYTarget.getField("NUM").getValue(),
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  inputXTarget.getField("NUM").setValue(coor.x);
                  inputYTarget.getField("NUM").setValue(coor.y);
                }
              }
            );
          };
          inputYTarget.getField("NUM").showEditor = function (e: Event) {
            Blockly.dialog.position(
              inputXTarget.getField("NUM").getValue(),
              inputYTarget.getField("NUM").getValue(),
              { x: e.x, y: e.y },
              function (coor: Vector2D) {
                if (coor !== null) {
                  inputXTarget.getField("NUM").setValue(coor.x);
                  inputYTarget.getField("NUM").setValue(coor.y);
                }
              }
            );
          };
        }
      });
    },
  };
  BlocklyJs["component_property_setter_x_y_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const x = BlocklyJs.valueToCode(block, "x", BlocklyJsOperator.ORDER_NONE);
    const y = BlocklyJs.valueToCode(block, "y", BlocklyJsOperator.ORDER_NONE);
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setTranslateXYByTime("${component[0]}-container-${component[1]}",${time}, ${x}, ${y});\n`;
    return code;
  };

  const xyStepTimeBlockGen = (componentsField: string[][]) => ({
    type: "component_property_setter_x_y_by_step_time",
    message0: "%{BKY_SETTER_X_Y_BY_STEP_TIME}",
    args0: [
      {
        type: "input_value",
        name: "time",
        check: "Number",
      },
      {
        type: "field_dropdown",
        name: "component_id",
        options: componentsField,
      },
      {
        type: "input_value",
        name: "x",
        check: "Number",
      },
      {
        type: "input_value",
        name: "y",
        check: "Number",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    style: "math_blocks",
  });
  Blockly.Blocks["component_property_setter_x_y_by_step_time"] = {
    init: function () {
      this.jsonInit(
        xyStepTimeBlockGen(
          components.length === 0 ? [["パーツ", ""]] : components
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputXTarget = this.getInputTargetBlock("x") as Blockly.Block;
        if (inputXTarget && inputXTarget.getField("NUM")) {
          inputXTarget.getField("NUM").setValidator((value: number) => {
            if (value > X_MAX) {
              return X_MAX;
            } else if (value < -X_MAX) {
              return -X_MAX;
            } else {
              return value;
            }
          });
        }
        const inputYTarget = this.getInputTargetBlock("y") as Blockly.Block;
        if (inputYTarget && inputYTarget.getField("NUM")) {
          inputYTarget.getField("NUM").setValidator((value: number) => {
            if (value > Y_MAX) {
              return Y_MAX;
            } else if (value < -Y_MAX) {
              return -Y_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_x_y_by_step_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const x = BlocklyJs.valueToCode(block, "x", BlocklyJsOperator.ORDER_NONE);
    const y = BlocklyJs.valueToCode(block, "y", BlocklyJsOperator.ORDER_NONE);
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setTranslateXYByStepAndTime("${component[0]}-container-${component[1]}",${time}, ${x}, ${y});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_order"] = {
    init: function () {
      const componentsField = new Blockly.FieldDropdown(() =>
        components.length === 0 ? [["パーツ", ""]] : components
      );
      componentsField.showEditor = showEditor;
      if (defaultComponent) {
        componentsField.setValue(defaultComponent);
      }
      this.appendDummyInput()
        .appendField(componentsField, "component_id")
        .appendField("%{BKY_DISPLAY_ORDER}")
        .appendField(
          new Blockly.FieldDropdown([
            ["%{BKY_DISPLAY_ORDER_TOP}", "1"],
            ["%{BKY_DISPLAY_ORDER_BOTTOM}", "0"],
          ]),
          "value"
        )
        .appendField("にする");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setStyle("logic_blocks");
    },
  };
  BlocklyJs["component_property_setter_order"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = block.getField("value").getValue();
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setOrder("${component[0]}-container-${
            component[1]
          }", ${Number(value)});\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_rotation"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_rotation",
          "%{BKY_GETTER_ROTATION}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_rotation"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getRotate("${component[0]}-container-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };

  Blockly.Blocks["component_property_setter_left_rotation"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_left_rotation",
          "%{BKY_SETTER_LEFT_ROTATION}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_left_rotation"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setRotate("${component[0]}-container-${component[1]}", -Number(${value}));\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_left_rotation_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_left_rotation_by_step",
          "%{BKY_SETTER_LEFT_ROTATION_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_left_rotation_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setRotateByStep("${component[0]}-container-${component[1]}", -Number(${value}));\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_left_rotation_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_left_rotation_by_time",
          "%{BKY_SETTER_LEFT_ROTATION_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_left_rotation_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setRotateByTime("${component[0]}-container-${component[1]}",${time}, -Number(${value}));\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_right_rotation"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_right_rotation",
          "%{BKY_SETTER_RIGHT_ROTATION}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_right_rotation"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setRotate("${component[0]}-container-${component[1]}", Number(${value}));\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_right_rotation_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_right_rotation_by_step",
          "%{BKY_SETTER_RIGHT_ROTATION_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_right_rotation_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setRotateByStep("${component[0]}-container-${component[1]}", Number(${value}));\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_right_rotation_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_right_rotation_by_time",
          "%{BKY_SETTER_RIGHT_ROTATION_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_setter_right_rotation_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `await codeGen.setRotateByTime("${component[0]}-container-${component[1]}",${time}, Number(${value}));\n`;
    return code;
  };

  Blockly.Blocks["component_property_getter_scale"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "component_property_getter_scale",
          "%{BKY_GETTER_SCALE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["component_property_getter_scale"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.getScale("${component[0]}-container-${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };

  Blockly.Blocks["component_property_setter_scale"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_scale",
          "%{BKY_SETTER_SCALE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > SCALE_MAX) {
              return SCALE_MAX;
            } else if (value < SCALE_MIN) {
              return SCALE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_scale"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setScale("${component[0]}-container-${component[1]}",${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_scale_by_step"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "component_property_setter_scale_by_step",
          "%{BKY_SETTER_SCALE_BY_STEP}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "logic_blocks"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > SCALE_MAX) {
              return SCALE_MAX;
            } else if (value < -SCALE_MAX) {
              return -SCALE_MAX;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_scale_by_step"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setScaleByStep("${component[0]}-container-${component[1]}",${value});\n`;
    return code;
  };

  Blockly.Blocks["component_property_setter_scale_by_time"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterByTimeBlock(
          "component_property_setter_scale_by_time",
          "%{BKY_SETTER_SCALE_BY_TIME}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Number"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
      this.setOnChange(() => {
        const inputTarget = this.getInputTargetBlock("value") as Blockly.Block;
        if (inputTarget && inputTarget.getField("NUM")) {
          inputTarget.getField("NUM").setValidator((value: number) => {
            if (value > SCALE_MAX) {
              return SCALE_MAX;
            } else if (value < SCALE_MIN) {
              return SCALE_MIN;
            } else {
              return value;
            }
          });
        }
      });
    },
  };
  BlocklyJs["component_property_setter_scale_by_time"] = function (
    block: Blockly.BlockSvg
  ) {
    const component = block.getField("component_id").getValue().split("_");
    const time = BlocklyJs.valueToCode(
      block,
      "time",
      BlocklyJsOperator.ORDER_NONE
    );
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.setScaleByTime("${component[0]}-container-${component[1]}", ${time}, ${value});\n`;
    return code;
  };
};
