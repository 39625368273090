import $ from "jquery";
import chroma from "chroma-js";

export const getSwitchBackgroundColor = (id: string) =>
  $(`#switch-svg-${id}`).data("backgroundColor");
export const setSwitchBackgroundColor = (id: string, value: string) => {
  const svg = $(`#switch-svg-${id}`);
  svg.data("backgroundColor", `${value}`);

  const thumbColorHsl = chroma(value).hsl();
  const thumbColorRgba = chroma(value).rgba();

  const trackColor = chroma
    .hsl(thumbColorHsl[0], thumbColorHsl[1], thumbColorHsl[2] + 0.1)
    .rgba();

  $(`#switch-shape-track-${id}`).attr(
    "fill",
    `rgba(${trackColor[0]},${trackColor[1]},${trackColor[2]},${thumbColorRgba[3]})`
  );
  $(`#switch-shape-thumb-${id}`).attr(
    "fill",
    `rgba(${thumbColorRgba[0]},${thumbColorRgba[1]},${thumbColorRgba[2]},${thumbColorRgba[3]})`
  );
};
