import $ from "jquery";
import anime from "animejs";
import chroma from "chroma-js";
import {
  getShadowColorFromFilter,
  getShadowRadiusFromFilter,
  getShadowRgbaColorFromFilter,
} from "common/blockly/codeGen";
import { SHADOW_SIZE_MIN, SHADOW_SIZE_MAX } from "common/components";

export const getTextFontFamily = (id: string) =>
  $(`#${id}`).css("font-family").split("-")[0];
export const setTextFontFamily = (id: string, value: string) => {
  const currentFont = $(`#${id}`).css("font-family").split("-");
  $(`#${id}`).css("font-family", `${value}-${currentFont[1]}`);
};

export const getTextFontWeight = (id: string) =>
  $(`#${id}`).css("font-family").split("-")[1];
export const setTextFontWeight = (id: string, value: string) => {
  const currentFont = $(`#${id}`).css("font-family").split("-");
  $(`#${id}`).css("font-family", `${currentFont[0]}-${value}`);
};

export const getTextFontSize = (id: string) =>
  Number($(`#${id}`).css("font-size").split("px")[0]);

export const getTextColor = (id: string) => $(`#${id}`).css("color");
export const setTextColor = (id: string, value: string) =>
  $(`#${id}`).css("color", `${value}`);

export const getTextShadowColor = (id: string) => {
  const obj = $(`#${id}`);
  const filter = obj.css("text-shadow");
  return getShadowColorFromFilter(filter);
};
export const setTextShadowColor = (id: string, value: string) => {
  const obj = $(`#${id}`);
  const filter = obj.css("text-shadow");
  const shadowRadius = getShadowRadiusFromFilter(filter);
  const color = chroma(value).rgb();
  obj.css(
    "text-shadow",
    `0px 0px ${shadowRadius}px rgba(${color[0]}, ${color[1]}, ${color[2]},${
      shadowRadius === 0 ? 0 : 1
    })`
  );
};

export const getTextShadowRadius = (id: string) => {
  const obj = $(`#${id}`);
  const filter = obj.css("text-shadow");
  const shadowRadius = getShadowRadiusFromFilter(filter);
  return shadowRadius;
};
export const setTextShadowRadius = (componentId: string, value: number) => {
  const obj = $(`#${componentId}`);
  const filter = obj.css("text-shadow");
  const color = getShadowRgbaColorFromFilter(filter);

  let target: number;
  if (value > SHADOW_SIZE_MAX) {
    target = SHADOW_SIZE_MAX;
  } else if (value < SHADOW_SIZE_MIN) {
    target = SHADOW_SIZE_MIN;
  } else {
    target = value;
  }

  obj.css(
    "text-shadow",
    `0px 0px ${target}px rgba(${color[0]}, ${color[1]}, ${color[2]}, ${
      target === 0 ? 0 : 1
    })`
  );
};
export const setTextShadowRadiusByStep = (id: string, value: number) => {
  const obj = $(`#${id}`);
  const filter = obj.css("text-shadow");
  const shadowRadius = getShadowRadiusFromFilter(filter);
  const color = getShadowRgbaColorFromFilter(filter);
  const newShadowRadius = value + shadowRadius;

  let target: number;
  if (newShadowRadius > SHADOW_SIZE_MAX) {
    target = SHADOW_SIZE_MAX;
  } else if (newShadowRadius < SHADOW_SIZE_MIN) {
    target = SHADOW_SIZE_MIN;
  } else {
    target = newShadowRadius;
  }

  obj.css(
    "text-shadow",
    `0px 0px ${target}px rgba(${color[0]}, ${color[1]}, ${color[2]}, ${
      target === 0 ? 0 : 1
    })`
  );
};
export const setTextShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const obj = $(`#${id}`);
  const filter = obj.css("text-shadow");
  const shadowRadius = getShadowRadiusFromFilter(filter);
  const color = getShadowRgbaColorFromFilter(filter);

  let target: number;
  if (value > SHADOW_SIZE_MAX) {
    target = SHADOW_SIZE_MAX;
  } else if (value < SHADOW_SIZE_MIN) {
    target = SHADOW_SIZE_MIN;
  } else {
    target = value;
  }

  return anime({
    targets: `#${id}`,
    textShadow: [
      `0px 0px ${shadowRadius}px rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`,
      `0px 0px ${target}px rgba(${color[0]}, ${color[1]}, ${color[2]}, ${
        target === 0 ? 0 : 1
      })`,
    ],
    duration: time * 1000,
    easing: "easeInOutQuad",
  }).finished;
};
