import produce from "immer";
import DragLayerComponent from "./drag";
import DesignComponent from "./design";
import RuntimeComponent from "./runtime";
import {
  ComponentType,
  BorderStypeType,
  DefaultComponentProperty,
} from "common/components/types";

const Line: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  RuntimeComponent: RuntimeComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_LINE";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_LINE";

    const initalWidth = 100;
    const initalHeight = 5;

    draft.style.defaultLayout.width = initalWidth;
    draft.style.defaultLayout.height = initalHeight;
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultView.borderStyle = BorderStypeType.SOLID;

    draft.style.layout.width = initalWidth;
    draft.style.layout.height = initalHeight;
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.view.borderStyle = BorderStypeType.SOLID;
  }),
};

export default Line;
