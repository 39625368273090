import $ from "jquery";

export * from "./size";
export * from "./border";
export * from "./shadow";
export * from "./background";

export const getSwitchValue = (id: string): boolean =>
  Boolean($(`#switch-svg-${id}`).data("value"));

export const setSwitchValue = (id: string, value: boolean) => {
  const svg = $(`#switch-svg-${id}`);
  const shape = $(`#switch-shape-${id}`);
  const thumb = $(`#switch-shape-thumb-${id}`);

  const width = shape.width();
  const height = shape.height();
  const borderWidth = Number(thumb.attr("stroke-width"));

  const MARGIN = width < height ? width * 0.1 : height * 0.1;
  const THUMB_BORDER_WIDTH = borderWidth * 0.1;
  const THUMB_RADIUS = width < height ? width * 0.4 : height * 0.4;

  if (!value) {
    thumb.attr("transform", `translate(${MARGIN + THUMB_BORDER_WIDTH / 2}, 0)`);
    svg.data("value", false);
  } else {
    thumb.attr(
      "transform",
      `translate(${
        width - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
      }, 0)`
    );
    svg.data("value", true);
  }
};
