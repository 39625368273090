import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;
  const width = property.style.layout.width * scale;
  const height = property.style.layout.height * scale;
  const borderWidth = property.style.view.borderWidth * scale;

  return (
    <svg
      preserveAspectRatio="none"
      width={width + borderWidth}
      height={height + borderWidth}
      viewBox={`${-borderWidth / 2} ${-borderWidth / 2} ${
        width + borderWidth
      } ${height + borderWidth}`}
    >
      <rect
        paintOrder="stroke"
        x={0}
        y={0}
        width={width}
        height={height}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        fill={property.style.view.backgroundColor}
      />
    </svg>
  );
};

export default WrapComponent;
