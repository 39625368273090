import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { BlocklyJsOperator } from "common/blockly";

export const InitMediaBlock = () => {
  Blockly.Blocks["take_photo_from_camera"] = {
    init: function () {
      this.jsonInit({
        type: "take_photo_from_camera",
        message0: "カメラからしゃしんをとる",
        output: "String",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["take_photo_from_camera"] = function (block: Blockly.BlockSvg) {
    const code = `await codeGen.takePhotoFromCamera()`;
    return [code, BlocklyJsOperator.ORDER_NONE];
  };
  Blockly.Blocks["take_photo_from_library"] = {
    init: function () {},
  };
  BlocklyJs["take_photo_from_library"] = function (block: Blockly.BlockSvg) {
    const code = "";
    return [code, BlocklyJsOperator.ORDER_NONE];
  };
};
