import $ from "jquery";
import anime from "animejs";
import {
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BORDER_RADIUS_MAX,
  BORDER_RADIUS_MIN,
} from "common/components";

export const getSwitchBorderColor = (id: string) =>
  $(`#switch-shape-${id}`).attr("stroke");
export const setSwitchBorderColor = (id: string, value: string) => {
  $(`#switch-shape-${id}`).attr("stroke", `${value}`);
  $(`#switch-shape-thumb-${id}`).attr("stroke", `${value}`);
};

export const getSwitchBorderWidth = (id: string): number =>
  Number($(`#switch-shape-${id}`).attr("stroke-width").split("px")[0]);
export const setSwitchBorderWidth = (id: string, value: number) => {
  const container = $(`#switch-container-${id}`);
  const svg = $(`#switch-svg-${id}`);
  const shape = $(`#switch-shape-${id}`);
  const thumb = $(`#switch-shape-thumb-${id}`);

  const switchValue = svg.data("value");
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;
  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }

  const delta = target - currentBorderWidth;
  container.css("width", currentContainerWidth + delta);
  container.css("height", currentContainerHeight + delta);
  svg.attr(
    "viewBox",
    `${Number(viewBox[0]) - delta / 2} ${Number(viewBox[1]) - delta / 2} ${
      Number(viewBox[2]) + delta
    } ${Number(viewBox[3]) + delta}`
  );

  const MARGIN =
    currentShapeWidth < currentShapeHeight
      ? currentShapeWidth * 0.1
      : currentShapeHeight * 0.1;
  const THUMB_BORDER_WIDTH = (currentBorderWidth + delta) * 0.1;
  const THUMB_RADIUS =
    currentShapeWidth < currentShapeHeight
      ? currentShapeWidth * 0.4
      : currentShapeHeight * 0.4;

  shape.attr("stroke-width", `${currentBorderWidth + delta}`);
  thumb.attr("stroke-width", `${THUMB_BORDER_WIDTH}`);
  thumb.attr(
    "stroke-transform",
    `translate(${
      switchValue
        ? currentShapeWidth - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
        : MARGIN + THUMB_BORDER_WIDTH / 2
    } 0)`
  );
};

export const setSwitchBorderWidthByStep = (id: string, value: number) => {
  const shape = $(`#switch-shape-${id}`);
  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);
  const newBorderWidth = currentBorderWidth + value;
  setSwitchBorderWidth(id, newBorderWidth);
};
export const setSwitchBorderWidthByTime = (
  id: string,
  time: number,
  value: number
) => {
  const container = $(`#switch-container-${id}`);
  const svg = $(`#switch-svg-${id}`);
  const shape = $(`#switch-shape-${id}`);
  const thumb = $(`#switch-shape-thumb-${id}`);

  const switchValue = svg.data("value");
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const currentBorderWidth = Number(shape.attr("stroke-width").split("px")[0]);

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }

  const delta = target - currentBorderWidth;

  const MARGIN =
    currentShapeWidth < currentShapeHeight
      ? currentShapeWidth * 0.1
      : currentShapeHeight * 0.1;
  const THUMB_BORDER_WIDTH = target * 0.1;
  const THUMB_RADIUS =
    currentShapeWidth < currentShapeHeight
      ? currentShapeWidth * 0.4
      : currentShapeHeight * 0.4;

  thumb.attr(
    "stroke-transform",
    `translate(${
      switchValue
        ? currentShapeWidth - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
        : MARGIN + THUMB_BORDER_WIDTH / 2
    } 0)`
  );

  return Promise.all([
    anime({
      targets: `#switch-container-${id}`,
      width: `+=${delta}`,
      height: `+=${delta}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-svg-${id}`,
      viewBox: `${Number(viewBox[0]) - delta / 2} ${
        Number(viewBox[1]) - delta / 2
      } ${Number(viewBox[2]) + delta} ${Number(viewBox[3]) + delta}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-shape-${id}`,
      strokeWidth: target,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-shape-thumb-${id}`,
      strokeWidth: THUMB_BORDER_WIDTH,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};

export const getSwitchBorderRadius = (id: string): number =>
  Number($(`#switch-shape-${id}`).data("borderRadius"));
export const setSwitchBorderRadius = (id: string, value: number) => {
  const shape = $(`#switch-shape-${id}`);
  const track = $(`#switch-shape-track-${id}`);
  const currentShapeWidth = shape.width();
  const currentShapeHeight = shape.height();
  const trackMargin = Number(track.data("trackMargin"));
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  shape.data("borderRadius", target);
  shape.attr("rx", size * (target / 200));
  track.attr("rx", (size - trackMargin * 2) * (target / 200));
};

export const setSwitchBorderRadiusByStep = (id: string, value: number) => {
  const newBorderRadius =
    Number($(`#switch-shape-${id}`).data("borderRadius")) + value;
  setSwitchBorderRadius(id, newBorderRadius);
};
export const setSwitchBorderRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const shape = $(`#switch-shape-${id}`);
  const track = $(`#switch-shape-track-${id}`);
  const currentShapeWidth = shape.width();
  const currentShapeHeight = shape.height();
  const trackMargin = Number(track.data("trackMargin"));
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  return Promise.all([
    anime({
      targets: `#switch-shape-${id}`,
      rx: size * (target / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#switch-shape-track-${id}`,
      rx: (size - trackMargin) * (target / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
