/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Blockly from "blockly";
import * as Sentry from "@sentry/react";
import BlocklyJs from "blockly/javascript";
import { AppDispatch, RootState } from "app/store";
import {
  InitMsg,
  InitColorBlock,
  InitVariableBlock,
  InitMathInitBlock,
  InitProceduresCallBlock,
} from "common/blockly";
import {
  InitSoundBlock,
  InitMediaBlock,
  InitAngleBlock,
  InitTextFontBlock,
  ComponentTypeIds,
} from "common/components";
import {
  actions,
  selectSceenById,
  selectAllScreens,
  selectBlocklyById,
} from "features/preview/slice";
import { InitComponentBlockly } from "features/preview/InitBlockly";

export const useGenerateEvent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [workspace, setWorkspace] = useState<Blockly.Workspace>(null);
  const [selectedScreenId, setSelectedScreenId] = useState<string>(null);
  const allScreens = useSelector((state: RootState) => selectAllScreens(state));
  const blockly = useSelector((state: RootState) =>
    selectBlocklyById(state, selectedScreenId)
  );
  const selectedScreen = useSelector((state: RootState) =>
    selectSceenById(state, selectedScreenId)
  );

  const [screenIndex, _setScreenIndex] = useState(allScreens.length - 1);
  const screenIndexRef = useRef(screenIndex);
  const setScreenIndex = (index: number) => {
    screenIndexRef.current = index;
    _setScreenIndex(index);
  };

  InitMsg("ja");
  InitSoundBlock();
  InitMediaBlock();
  InitAngleBlock();
  InitColorBlock();
  InitVariableBlock();
  InitMathInitBlock();
  InitTextFontBlock();
  InitProceduresCallBlock();

  useEffect(() => {
    const workspace = new Blockly.Workspace();
    BlocklyJs.init(workspace);
    setWorkspace(workspace);
  }, []);

  useEffect(() => {
    if (screenIndex > -1) {
      setSelectedScreenId(allScreens[screenIndex].id);
    }
  }, [screenIndex]);

  const onWorkspaceChange = (event: Blockly.Events.BlockCreate) => {
    switch (event.type) {
      case Blockly.Events.BLOCK_CREATE:
        const eventCode = [];
        BlocklyJs.init(workspace);
        workspace
          .getTopBlocks(true)
          .filter((block) => block.isEnabled())
          .forEach((block) => {
            const filed = block.getField(
              "component_id"
            ) as Blockly.FieldDropdown;
            if (filed) {
              const componentId = filed.getValue();
              if (componentId !== "") {
                const code = BlocklyJs.blockToCode(block);
                eventCode.push({
                  id: block.id,
                  eventId: `${componentId.split("_")[1]}_${block.type}`,
                  componentId: componentId.split("_")[1],
                  code: code,
                });
              }
            } else {
              if (
                block.type === "procedures_defnoreturn" ||
                block.type === "procedures_defreturn"
              ) {
                BlocklyJs.blockToCode(block);
              }
            }
          });

        const funCode = BlocklyJs.finish("");
        const code = funCode.replace(/function /g, "async $&"); // safari maybe has some problems.

        if (code.split("\n")[0].includes("var")) {
          const variables = [];
          BlocklyJs.nameDB_.setVariableMap(workspace.getVariableMap());
          workspace.getAllVariables().forEach((variable) => {
            const varName = BlocklyJs.nameDB_.getName(
              variable.name,
              Blockly.VARIABLE_CATEGORY_NAME
            );
            variables.push(`${varName} = "";`);
          });

          const splitStr = funCode.indexOf(";");
          const proceduresCode = code.substring(splitStr + 1);
          eventCode.push({
            id: `${selectedScreenId}_variables`,
            eventId: `${selectedScreenId}_variables`,
            componentId: selectedScreenId,
            code: variables.join(""),
          });
          eventCode.push({
            id: `${selectedScreenId}_procedures`,
            eventId: `${selectedScreenId}_procedures`,
            componentId: selectedScreenId,
            code: proceduresCode,
          });
        } else {
          eventCode.push({
            id: `${selectedScreenId}_variables`,
            eventId: `${selectedScreenId}_variables`,
            componentId: selectedScreenId,
            code: "",
          });
          eventCode.push({
            id: `${selectedScreenId}_procedures`,
            eventId: `${selectedScreenId}_procedures`,
            componentId: selectedScreenId,
            code: code,
          });
        }

        dispatch(actions.updateEvents(eventCode));
        break;
      case Blockly.Events.FINISHED_LOADING:
        setScreenIndex(screenIndexRef.current - 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (workspace && selectedScreen) {
      InitComponentBlockly(
        allScreens.map((screen) => [
          screen.name,
          `${ComponentTypeIds.SCREEN}_${screen.id}`,
        ]),
        selectedScreen,
        selectedScreen.id
      );
      if (blockly && blockly.xmlText) {
        try {
          const xml = Blockly.Xml.textToDom(blockly.xmlText);
          Blockly.Xml.domToWorkspace(xml, workspace);
        } catch (error) {
          console.log(error);
          console.log(blockly.xmlText);
          Sentry.captureMessage("failed to dom to workspace");
        }
      } else {
        setScreenIndex(screenIndexRef.current - 1);
      }
      workspace.addChangeListener(onWorkspaceChange);
    }

    return () => {
      if (workspace) {
        workspace.clear();
        workspace.removeChangeListener(onWorkspaceChange);
      }
    };
  }, [workspace, selectedScreen]);
};
