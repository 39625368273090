import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import {
  BlocklyJsOperator,
  initPropertyGetterBlock,
  initPropertySetterBlock,
} from "common/blockly";

export const InitToggleBlock = (
  components: string[][],
  defaultComponent?: string | undefined
) => {
  function showEditor(e) {
    e.stopPropagation();
    e.preventDefault();
    Blockly.dialog.component(
      { x: e.x, y: e.y },
      this.getValue(),
      components.length === 0 ? [["パーツ", ""]] : components,
      function (value: string) {
        if (value !== null) {
          this.setValue(value);
        }
      }.bind(this)
    );
  }

  Blockly.Blocks["toggle_getter_value"] = {
    init: function () {
      this.jsonInit(
        initPropertyGetterBlock(
          "toggle_getter_value",
          "%{BKY_TOGGLE_GETTER_VALUE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Boolean"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["toggle_getter_value"] = function (block: Blockly.BlockSvg) {
    const component = block.getField("component_id").getValue().split("_");
    const code =
      component[0] === ""
        ? ""
        : `codeGen.get${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Value("${component[1]}")`;
    return [code, BlocklyJsOperator.ORDER_MULTIPLICATION];
  };

  Blockly.Blocks["toggle_setter_value"] = {
    init: function () {
      this.jsonInit(
        initPropertySetterBlock(
          "toggle_setter_value",
          "%{BKY_TOGGLE_SETTER_VALUE}",
          components.length === 0 ? [["パーツ", ""]] : components,
          "math_blocks",
          "Boolean"
        )
      );
      const filed = this.getField("component_id") as Blockly.FieldDropdown;
      filed.showEditor = showEditor;
      if (defaultComponent) {
        this.getField("component_id").setValue(defaultComponent);
      }
    },
  };
  BlocklyJs["toggle_setter_value"] = function (block: Blockly.BlockSvg) {
    const component = block.getField("component_id").getValue().split("_");
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const code =
      component[0] === ""
        ? ""
        : `codeGen.set${
            component[0].charAt(0).toUpperCase() + component[0].slice(1)
          }Value("${component[1]}",${value});\n`;
    return code;
  };
};
