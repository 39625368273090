import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const PADDING_VERTICAL = 10;
  const PADDING_HORIZONTAL = 10;
  const size = Math.min(
    property.style.layout.width - PADDING_HORIZONTAL * 2,
    property.style.layout.height - PADDING_VERTICAL * 2
  );
  return (
    <div id={id} className="flex-row-center w-full h-full">
      <input
        type="text"
        readOnly
        className="!select-text pointer-events-none m-0"
        maxLength={property.style.layout.width}
        placeholder={property.placeholder}
        value={property.text}
        style={{
          paddingLeft: PADDING_HORIZONTAL,
          paddingRight: PADDING_HORIZONTAL,
          paddingTop: PADDING_VERTICAL,
          paddingBottom: PADDING_VERTICAL,
          width: property.style.layout.width - PADDING_HORIZONTAL * 2,
          height: property.style.layout.height - PADDING_VERTICAL * 2,
          boxSizing: "content-box",
          borderStyle: property.style.view.borderStyle,
          borderColor: property.style.view.borderColor,
          borderWidth: property.style.view.borderWidth / 2,
          borderRadius: size * (property.style.view.borderRadius / 200),
          opacity: property.style.view.opacity / 100,
          color: property.style.text.color,
          fontFamily: property.style.text.fontFamily,
          fontSize: property.style.text.fontSize,
          letterSpacing: property.style.text.letterSpacing,
          backgroundColor: property.style.view.backgroundColor,
          textShadow:
            property.style.text.textShadowRadius !== 0 &&
            `${property.style.text.textShadowOffset.width}px ${property.style.text.textShadowOffset.height}px ${property.style.text.textShadowRadius}px ${property.style.text.textShadowColor}`,
        }}
        onChange={() => {}}
      />
    </div>
  );
};

export default WrapComponent;
