export const takePhotoFromCamera = () => {
  const preview = document.getElementById("camera-preview");
  preview.style.display = "block";

  const video = document.getElementById("video-preview") as HTMLVideoElement;
  video.style.display = "block";

  navigator.mediaDevices
    .getUserMedia({ audio: false, video: true })
    .then(function (stream) {
      video.srcObject = stream;
      video.onloadedmetadata = function (e) {
        video.play();
      };
    })
    .catch(function (err) {});

  return new Promise((resolve, reject) => {
    const confirm = document.getElementById("camera-capture-preview-confirm");
    confirm.onclick = () => {
      const canvas = document.getElementById(
        "camera-capture-preview-canvas"
      ) as HTMLCanvasElement;
      const dataUrl = canvas.toDataURL("image/png");

      const container = document.getElementById("camera-capture-preview");
      container.style.display = "none";

      resolve(dataUrl);
    };
  });
};
