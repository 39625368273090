import i18n from "i18n-js";
import { DragLayerComponentProps } from "common/components/types";

const WrapComponent = (props: DragLayerComponentProps) => {
  const { scale, property } = props;
  const PADDING_VERTICAL = 10;
  const PADDING_HORIZONTAL = 10;
  const size = Math.min(
    property.style.layout.width - PADDING_HORIZONTAL * 2,
    property.style.layout.height - PADDING_VERTICAL * 2
  );
  return (
    <div
      style={{
        width: property.style.layout.width,
        height: property.style.layout.height,
        backfaceVisibility: "hidden",
        WebkitBackfaceVisibility: "hidden",
        transform: `translateZ(0) scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <input
        type="text"
        placeholder={i18n.t(property.placeholder)}
        defaultValue={i18n.t(property.text)}
        style={{
          paddingLeft: PADDING_HORIZONTAL,
          paddingRight: PADDING_HORIZONTAL,
          paddingTop: PADDING_VERTICAL,
          paddingBottom: PADDING_VERTICAL,
          width: property.style.layout.width - PADDING_HORIZONTAL * 2,
          height: property.style.layout.height - PADDING_VERTICAL * 2,
          borderStyle: property.style.view.borderStyle,
          borderColor: property.style.view.borderColor,
          borderWidth: property.style.view.borderWidth / 2,
          borderRadius: size * (property.style.view.borderRadius / 200),
          opacity: property.style.view.opacity / 100,
          fontFamily: property.style.text.fontFamily,
          fontSize: property.style.text.fontSize,
          letterSpacing: property.style.text.letterSpacing,
        }}
      />
    </div>
  );
};

export default WrapComponent;
