export const OPACITY_MIN = 0;
export const OPACITY_MAX = 100;
export const SHADOW_SIZE_MIN = 0;
export const SHADOW_SIZE_MAX = 100;
export const BORDER_SIZE_MIN = 0;
export const BORDER_SIZE_MAX = 100;
export const BORDER_RADIUS_MIN = 0;
export const BORDER_RADIUS_MAX = 100;
export const WIDTH_MIN = 30;
export const WIDTH_MAX = 1024;
export const HEIGHT_MIN = 15;
export const HEIGHT_MAX = 768;
export const SCALE_MIN = -2;
export const SCALE_MAX = 2;
export const X_MIN = 0;
export const X_MAX = 1024;
export const Y_MIN = 0;
export const Y_MAX = 768;
export const TEXTINPUT_MAX = 20;
export const FONT_SIZE_MIN = 14;
export const FONT_SIZE_MAX = 50;
export enum RENDER_ORDER {
  BACK = 0,
  FRONT = 1,
}
