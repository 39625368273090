import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getShapeShadowColor = (id: string) =>
  getShadowColor(`shape-container-${id}`);
export const setShapeShadowColor = (id: string, value: string) =>
  setShadowColor(`shape-container-${id}`, value);

export const getShapeShadowRadius = (id: string) =>
  getShadowRadius(`shape-container-${id}`);
export const setShapeShadowRadius = (id: string, value: number) =>
  setShadowRadius(`shape-container-${id}`, value);
export const setShapeShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`shape-container-${id}`, value);
export const setShapeShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`shape-container-${id}`, time, value);
