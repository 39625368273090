import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;

  return (
    <svg
      id={id}
      display="block"
      preserveAspectRatio="none"
      viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${
        property.style.layout.width + SizeOffset
      } ${property.style.layout.height + SizeOffset}`}
    >
      <rect
        x={0}
        y={0}
        rx={property.style.layout.height / 2}
        ry={property.style.layout.height / 2}
        paintOrder="stroke"
        width={property.style.layout.width}
        height={property.style.layout.height}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        fill={property.style.view.backgroundColor}
      />
    </svg>
  );
};

export default WrapComponent;
