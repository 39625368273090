/* eslint-disable no-new-func */
import $ from "jquery";
import chroma from "chroma-js";
import * as codeGen from "common/blockly/codeGen";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property, navigate, events, proceduresCode } = props;
  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const MARGIN = width < height ? width * 0.1 : height * 0.1;
  const THUMB_BORDER_WIDTH = property.style.view.borderWidth * 0.1;
  const THUMB_RADIUS = width < height ? width * 0.4 : height * 0.4;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;
  const thumbColorHsl = chroma(property.style.view.backgroundColor).hsl();
  const thumbColorRgba = chroma(property.style.view.backgroundColor).rgba();
  const trackColor = chroma
    .hsl(thumbColorHsl[0], thumbColorHsl[1], thumbColorHsl[2] + 0.1)
    .rgba();
  const size = Math.min(width, height);

  const onValueChangeEvents = events
    .filter((event) =>
      event.eventId.includes(`${id}_switch_value_change_event`)
    )
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnValueChange = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const svg = $(`#switch-svg-${id}`);
    const shape = $(`#switch-shape-${id}`);
    const thumb = $(`#switch-shape-thumb-${id}`);
    const value = svg.data("value");
    const width = shape.width();
    const height = shape.height();

    const MARGIN = width < height ? width * 0.1 : height * 0.1;
    const THUMB_BORDER_WIDTH = property.style.view.borderWidth * 0.1;
    const THUMB_RADIUS = width < height ? width * 0.4 : height * 0.4;

    if (value) {
      thumb.attr(
        "transform",
        `translate(${MARGIN + THUMB_BORDER_WIDTH / 2}, 0)`
      );
      svg.data("value", false);
    } else {
      thumb.attr(
        "transform",
        `translate(${
          width - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
        }, 0)`
      );
      svg.data("value", true);
    }

    handleOnValueChange(onValueChangeEvents, navigate, codeGen)(event);
  };

  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();

  return (
    <RuntimeContainer {...props} onClick={handleOnClick}>
      <div
        id={`switch-shape-container-${id}`}
        className="w-full h-full"
        style={{
          filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
            property.style.shadow.shadowOffset.height
          }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
            ${shadowColor[1]},
            ${shadowColor[2]},
            ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
          ))`,
        }}
      >
        <svg
          id={`switch-svg-${id}`}
          data-value={property.value}
          data-background-color={property.style.view.backgroundColor}
          width="100%"
          height="100%"
          preserveAspectRatio="none"
          viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${
            property.style.layout.width + SizeOffset
          } ${property.style.layout.height + SizeOffset}`}
        >
          <rect
            id={`switch-shape-${id}`}
            data-border-radius={property.style.view.borderRadius}
            x={0}
            y={0}
            rx={size * (property.style.view.borderRadius / 200)}
            width={width}
            height={height}
            paintOrder="stroke"
            strokeWidth={property.style.view.borderWidth}
            stroke={property.style.view.borderColor}
            fill="white"
          />
          <rect
            id={`switch-shape-track-${id}`}
            data-track-margin={MARGIN}
            x={MARGIN}
            y={MARGIN}
            rx={(size - MARGIN * 2) * (property.style.view.borderRadius / 200)}
            width={width - MARGIN * 2}
            height={height - MARGIN * 2}
            paintOrder="stroke"
            fill={`rgba(${trackColor[0]}, ${trackColor[1]}, ${trackColor[2]}, ${thumbColorRgba[3]})`}
          />
          <circle
            id={`switch-shape-thumb-${id}`}
            cx={THUMB_RADIUS}
            cy={THUMB_RADIUS + MARGIN}
            r={THUMB_RADIUS}
            paintOrder="stroke"
            fill={String(property.style.view.backgroundColor)}
            strokeWidth={THUMB_BORDER_WIDTH}
            stroke={property.style.view.borderColor}
            transform={`translate(${
              property.value
                ? width - THUMB_RADIUS * 2 - MARGIN - THUMB_BORDER_WIDTH / 2
                : MARGIN + THUMB_BORDER_WIDTH / 2
            } 0)`}
          />
        </svg>
      </div>
    </RuntimeContainer>
  );
};

export default WrapComponent;
