import {
  Screen,
  Button,
  Label,
  TextInput,
  CheckBox,
  Switch,
  Image,
  Stamp,
  Drawing,
} from "./actions";
import { ComponentType } from "./types";
import { Circle, Line, Rectangle, Star, Triangle } from "./shapes";

export * from "./blockly";
export * from "./actions";
export * from "./shapes";

export enum ComponentCategory {
  ACTION = "action",
  SHAPE = "shape",
}

export enum ComponentTypeIds {
  SCREEN = "screen",
  BUTTON = "button",
  LABEL = "label",
  TEXTINPUT = "textinput",
  CHECKBOX = "checkbox",
  SWITCH = "switch",
  IMAGE = "image",
  STAMP = "stamp",
  DRAWING = "drawing",
  CIRCLE = "circle",
  LINE = "line",
  RECTANGLE = "rectangle",
  STAR = "star",
  TRIANGLE = "triangle",
}

type ComponentMeta = {
  id: string;
  component: ComponentType;
  category: ComponentCategory;
};

interface ComponentManagerType {
  [propName: string]: ComponentMeta;
}

export const ComponentManager: ComponentManagerType = {
  [ComponentTypeIds.SCREEN]: {
    id: ComponentTypeIds.SCREEN,
    component: Screen,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.BUTTON]: {
    id: ComponentTypeIds.BUTTON,
    component: Button,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.LABEL]: {
    id: ComponentTypeIds.LABEL,
    component: Label,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.TEXTINPUT]: {
    id: ComponentTypeIds.TEXTINPUT,
    component: TextInput,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.CHECKBOX]: {
    id: ComponentTypeIds.CHECKBOX,
    component: CheckBox,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.SWITCH]: {
    id: ComponentTypeIds.SWITCH,
    component: Switch,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.IMAGE]: {
    id: ComponentTypeIds.IMAGE,
    component: Image,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.STAMP]: {
    id: ComponentTypeIds.STAMP,
    component: Stamp,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.DRAWING]: {
    id: ComponentTypeIds.DRAWING,
    component: Drawing,
    category: ComponentCategory.ACTION,
  },
  [ComponentTypeIds.CIRCLE]: {
    id: ComponentTypeIds.CIRCLE,
    component: Circle,
    category: ComponentCategory.SHAPE,
  },
  [ComponentTypeIds.LINE]: {
    id: ComponentTypeIds.LINE,
    component: Line,
    category: ComponentCategory.SHAPE,
  },
  [ComponentTypeIds.RECTANGLE]: {
    id: ComponentTypeIds.RECTANGLE,
    component: Rectangle,
    category: ComponentCategory.SHAPE,
  },
  [ComponentTypeIds.STAR]: {
    id: ComponentTypeIds.STAR,
    component: Star,
    category: ComponentCategory.SHAPE,
  },
  [ComponentTypeIds.TRIANGLE]: {
    id: ComponentTypeIds.TRIANGLE,
    component: Triangle,
    category: ComponentCategory.SHAPE,
  },
};

export default ComponentManager;
export * from "./types";
export * from "./utils";
