import produce from "immer";
import DragLayerComponent from "./drag";
import DesignComponent from "./design";
import RuntimeComponent from "./runtime";
import {
  ComponentType,
  DefaultComponentProperty,
} from "common/components/types";

const Switch: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  RuntimeComponent: RuntimeComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_SWITCH";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_SWITCH";
    draft.value = true;

    const initalWidth = 50;
    const initalHeight = 30;

    draft.style.defaultLayout.width = initalWidth;
    draft.style.defaultLayout.height = initalHeight;
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultView.borderWidth = 4;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderRadius = 100;

    draft.style.layout.width = initalWidth;
    draft.style.layout.height = initalHeight;
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.view.borderWidth = 4;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderRadius = 100;

    draft.events = ["switch_value_change_event"];
  }),
};

export default Switch;
