import { creatStarPoint } from "common/components/utils";
import { DesignWrapComponentProps } from "common/components/types";

const WrapComponent = (props: DesignWrapComponentProps) => {
  const { id, property } = props;
  const SizeOffset =
    property.style.view.borderWidth + property.style.shadow.shadowRadius;

  const width = property.style.layout.width;
  const height = property.style.layout.height;
  const numPoints = property.style.star.numPoints;
  const innerRadius = property.style.star.innerRadius;
  const outerRadius = property.style.star.outerRadius;

  const pointsStr = creatStarPoint(
    width,
    height,
    numPoints,
    innerRadius,
    outerRadius
  );

  return (
    <svg
      id={id}
      overflow="visible"
      preserveAspectRatio="none"
      width="100%"
      height="100%"
      viewBox={`${-SizeOffset / 2} ${-SizeOffset / 2} ${width + SizeOffset} ${
        height + SizeOffset
      }`}
    >
      <polygon
        paintOrder="stroke"
        points={pointsStr}
        stroke={property.style.view.borderColor}
        strokeWidth={property.style.view.borderWidth}
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={property.style.view.backgroundColor}
      />
    </svg>
  );
};

export default WrapComponent;
