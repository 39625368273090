import produce from "immer";
import DragLayerComponent from "./drag";
import DesignComponent from "./design";
import RuntimeComponent from "./runtime";
import {
  ComponentType,
  TextStyleProps,
  DefaultComponentProperty,
} from "common/components/types";

const TextInput: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  RuntimeComponent: RuntimeComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.text = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.defaultText = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.secureTextEntry = false;
    draft.maxLength = 100;

    const initalWidth = 84;
    const initalHeight = 40;

    draft.style.defaultText = TextStyleProps;
    draft.style.defaultLayout.width = initalWidth;
    draft.style.defaultLayout.height = initalHeight;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderWidth = 2;
    draft.style.defaultView.borderRadius = 30;

    draft.style.text = TextStyleProps;
    draft.style.layout.width = initalWidth;
    draft.style.layout.height = initalHeight;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderWidth = 2;
    draft.style.view.borderRadius = 15;

    draft.events = ["textinput_value_change_event"];
  }),
};

export default TextInput;
