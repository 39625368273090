/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  User,
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  connectAuthEmulator,
} from "firebase/auth";
import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";
import { AppDispatch } from "app/store";
import { getKidsPreviewRuntimeLatestVersion } from "app/api";
import Constants from "common/constant";

const firebaseConfig = {
  apiKey: Constants.apiKey,
  authDomain: Constants.authDomain,
  databaseURL: Constants.databaseUrl,
  projectId: Constants.projectId,
  storageBucket: Constants.storageBucket,
  messagingSenderId: Constants.messagingSenderId,
  appId: Constants.appId,
  measurementId: Constants.measurementId,
};

const firebase = initializeApp(firebaseConfig);

export default firebase;

export interface AuthUser {
  user?: User | null;
}
const user: AuthUser = { user: null };
export const AuthContext = createContext(user);

export const AuthRoute = (props: {
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | null;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [authing, setAuthing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();
    if (process.env.NODE_ENV === "development") {
      connectAuthEmulator(
        auth,
        `http://${Constants.firebaseEmulatorsHost}:${Constants.firebaseEmulatorsAuthPort}`,
        { disableWarnings: true }
      );
    }

    const unsubscribe = onAuthStateChanged(auth, async (user: User) => {
      setUser(user);
      if (!user) {
        await signInAnonymously(auth);
      } else {
        setUser(user);
        setAuthing(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const currentVersion = `${Constants.major}.${Constants.minor}.${Constants.buildNumber}`;
    dispatch(getKidsPreviewRuntimeLatestVersion({ key: Constants.apiAuthKey }))
      .then(unwrapResult)
      .then(async (result) => {
        if (result.version !== currentVersion && !window.location.hash) {
          window.location.href =
            window.location.href + `#loaded?version=${result.version}`;
          window.location.reload();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException("failed to load latest app version info.");
        setLoading(false);
      });
  }, []);

  return authing || loading ? (
    <></>
  ) : (
    <AuthContext.Provider value={{ user }}>
      {props.children}
    </AuthContext.Provider>
  );
};
