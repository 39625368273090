/* eslint-disable no-new-func */
import chroma from "chroma-js";
import * as codeGen from "common/blockly/codeGen";
import RuntimeContainer from "../RuntimeContainer";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const { id, property, navigate, events, proceduresCode } = props;

  const onClickEvents = events
    .filter((event) => event.eventId.includes(`${id}_label_click_event`))
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnClick = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  const shadowColor = chroma(property.style.shadow.shadowColor).rgba();
  const textShadowColor = chroma(property.style.text.textShadowColor).rgba();
  return (
    <RuntimeContainer
      {...props}
      onClick={handleOnClick(onClickEvents, navigate, codeGen)}
    >
      <div
        id={`label-shape-container-${id}`}
        className="flex-row-center w-full h-full"
        style={{
          filter: `drop-shadow(${property.style.shadow.shadowOffset.width}px ${
            property.style.shadow.shadowOffset.height
          }px ${property.style.shadow.shadowRadius}px rgba(${shadowColor[0]},
            ${shadowColor[1]},
            ${shadowColor[2]},
            ${property.style.shadow.shadowRadius === 0 ? 0 : shadowColor[3]}
          ))`,
        }}
      >
        <p
          id={`label-text-${id}`}
          className="select-none m-0 align-middle whitespace-pre-line"
          style={{
            fontFamily: property.style.text.fontFamily,
            letterSpacing: property.style.text.letterSpacing,
            fontSize: property.style.text.fontSize,
            color: property.style.text.color,
            textAlign: "center",
            opacity: property.style.text.opacity / 100,
            textShadow: `${property.style.text.textShadowOffset.width}px ${
              property.style.text.textShadowOffset.height
            }px ${property.style.text.textShadowRadius}px rgba(${
              textShadowColor[0]
            },
              ${textShadowColor[1]},
              ${textShadowColor[2]},
              ${
                property.style.text.textShadowRadius === 0
                  ? 0
                  : textShadowColor[3]
              }
            )`,
          }}
        >
          {property.text}
        </p>
      </div>
    </RuntimeContainer>
  );
};

export default WrapComponent;
