import $ from "jquery";
import anime from "animejs";
import {
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BORDER_RADIUS_MAX,
  BORDER_RADIUS_MIN,
} from "common/components";

export const getTextinputBorderColor = (id: string) =>
  $(`#textinput-text-${id}`).css("border-color");
export const setTextinputBorderColor = (id: string, value: string) =>
  $(`#textinput-text-${id}`).css("border-color", `${value}`);

export const getTextinputBorderWidth = (id: string): number =>
  Number($(`#textinput-text-${id}`).css("border-width").split("px")[0]) * 2;
export const setTextinputBorderWidth = (id: string, value: number) => {
  const container = $(`#textinput-container-${id}`);
  const textinput = $(`#textinput-text-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(textinput.css("border-width").split("px")[0]) * 2;

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  textinput.css("border-width", `${target / 2}`);
};
export const setTextinputBorderWidthByStep = (id: string, value: number) => {
  const container = $(`#textinput-container-${id}`);
  const textinput = $(`#textinput-text-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(textinput.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  textinput.css("border-width", `${target / 2}`);
};
export const setTextinputBorderWidthByTime = (
  id: string,
  time: number,
  value: number
) => {
  const textinput = $(`#textinput-text-${id}`);
  const currentBorderWidth =
    Number(textinput.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  return Promise.all([
    anime({
      targets: `#textinput-container-${id}`,
      width: `+=${delta}`,
      height: `+=${delta}`,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
    anime({
      targets: `#textinput-text-${id}`,
      borderWidth: value / 2,
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};

export const getTextinputBorderRadius = (id: string): number =>
  Number($(`#textinput-text-${id}`).data("borderRadius"));
export const setTextinputBorderRadius = (id: string, value: number) => {
  const textinput = $(`#textinput-text-${id}`);
  const currentShapeWidth = textinput.width();
  const currentShapeHeight = textinput.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  textinput.data("borderRadius", target);
  textinput.css("border-radius", size * (target / 200));
};

export const setTextinputBorderRadiusByStep = (id: string, value: number) => {
  const newBorderRadius =
    Number($(`#textinput-text-${id}`).data("borderRadius")) + value;
  setTextinputBorderRadius(id, newBorderRadius);
};
export const setTextinputBorderRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const textinput = $(`#textinput-text-${id}`);
  const currentShapeWidth = textinput.width();
  const currentShapeHeight = textinput.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }
  textinput.data("borderRadius", target);
  return Promise.all([
    anime({
      targets: `#textinput-${id}`,
      borderRadius: size * (target / 200),
      duration: time * 1000,
      easing: "easeInOutQuad",
    }).finished,
  ]);
};
