import { useState } from "react";
import { Vector2D } from "common/types";
import throttle from "lodash-es/throttle";
import { BASE_WIDTH, BASE_HEIGHT } from "common/constant";

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const lowercase = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toLowerCase() + s.slice(1);
};

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const calScale = (width: number, height: number) => {
  const scaleX = width / BASE_WIDTH;
  const scaleY = height / BASE_HEIGHT;

  return scaleX < scaleY ? scaleX : scaleY;
};

export const getRandomArbitrary = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const useScale = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  window.addEventListener(
    "resize",
    throttle((e) => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 5000)
  );

  const scale = calScale(width, height);
  const ratio = width / height;
  return { width, height, scale, ratio };
};

export const calcVector = (x: number, y: number, radius: number): Vector2D => {
  const refPointDist = Math.hypot(y, x);
  const refPointRadius = Math.atan2(y, x) + radius;
  return {
    x: refPointDist * Math.cos(refPointRadius),
    y: refPointDist * Math.sin(refPointRadius),
  };
};

export const shuffle = (array: any[]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    let rand = Math.floor(Math.random() * (i + 1));
    // 配列の数値を入れ替える
    [array[i], array[rand]] = [array[rand], array[i]];
  }
  return array;
};
